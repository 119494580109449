import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import {
  REMOVE_MENU_OPTION,
  SELECT_MENU_OPTION,
  ADD_MENU_OPTION_PRODUCT,
  REMOVE_MENU_OPTION_PRODUCT,
  SET_PROPOSALS_MENU,
  SET_PROPOSALS_PRODUCTS,
  SET_SELECTED_PROPOSALS_PRODUCTS,
  SET_MENU_OPTION_TO_ADD_EDIT,
  ADD_MENU_OPTION,
  EDIT_MENU_OPTION,
  UPDATE_PRODUCT_CONFIGURATION,
  UPDATE_PRODUCT_CONFIGURATION_IN_MENU,
  SET_INITIAL_PROPOSALS_MENU,
  SET_IS_LOADING,
  CLEAR,
  SET_MENU_GENERAL_FORM_VALUES,
  SET_PARTNER_LINKS_BY_ID,
} from "./actionTypes";
import { MenuConstructorState } from "./types";
import { initialMenuOptionOnAddEdit, initialProposalMenu } from "../constants";
import { collectFactsToCheckFromProduct } from "modules/desking/lib/collectFactsToCheckFromProduct";
import { collectFactsSkippedOnProduct } from "modules/desking/lib/collectFactsSkippedOnProduct";
import { FactToCheck, ProposalProductCardModes } from "modules/desking/types";
import { immutableUtils } from "utils/immutable";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

const initialState: MenuConstructorState = {
  proposalMenu: initialProposalMenu,
  proposalProducts: {
    products: [],
    productsConfiguration: {},
    productsConfigurationInMenu: {},
    productsFactsToCheck: {},
    productFactsSkipped: {},
  },
  selectedProposalsProducts: [],
  menuOptionToAddEdit: { ...initialMenuOptionOnAddEdit },
  selectedMenuOption: null,
  archivedMenuOptions: [],
  initialProposalMenu: { ...initialProposalMenu },
  partnerLinksById: {},
  isLoading: true,
};

function setProducts(state: MenuConstructorState, products: ProposalProduct[]): MenuConstructorState {
  const productFactDetails = products.reduce<{
    productFactsToCheck: Record<string, FactToCheck[]>;
    productFactsSkipped: Record<string, FactToCheck[]>;
  }>(
    (acc, product) => {
      acc.productFactsToCheck[product.proposalProductId] = collectFactsToCheckFromProduct(product);
      acc.productFactsSkipped[product.proposalProductId] = collectFactsSkippedOnProduct(
        product,
        ProposalProductCardModes.Constructor
      );
      return acc;
    },
    {
      productFactsToCheck: {},
      productFactsSkipped: {},
    }
  );

  return {
    ...state,
    proposalProducts: {
      ...state.proposalProducts,
      // @ts-expect-error: TODO: Update typing
      products,
      // @ts-expect-error: TODO: Update typing
      productsFactsToCheck:
        products?.length > 0
          ? products.reduce((acc: any, product: any) => {
              const factsToCheck = collectFactsToCheckFromProduct(product, ProposalProductCardModes.Constructor);
              acc[product.proposalProductId] = factsToCheck;
              return acc;
            })
          : [],
      productFactsSkipped: productFactDetails.productFactsSkipped,
      productsConfiguration:
        products?.length > 0
          ? products.reduce((acc: any, product: any) => {
              acc[product.proposalProductId] = {
                cost: product?.cost ?? 0,
                retailCost: product?.retailCost ?? 0,
                markup: product?.markup ?? { markup: 0, type: "FLAT" },
                ...(product?.aftermarketProduct?.config?.criteriaValues[0] ?? {}),
              };

              return acc;
            }, {})
          : [],
    },
  };
}

export const menuConstructorReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PROPOSALS_MENU:
      return {
        ...state,
        proposalMenu: {
          ...payload,
          menuOptions: payload.menuOptions.map((option: any) => ({
            ...option,
            products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
          })),
        },
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: payload.menuOptions.reduce((menuAcc: any, option: any) => {
            menuAcc[option.name] = option.products.reduce((acc: any, product: any) => {
              acc[product.proposalProductId] = {
                cost: product?.cost ?? 0,
                retailCost: product?.retailCost ?? 0,
                markup: product?.markup ?? { markup: 0, type: "FLAT" },
                ...(product?.aftermarketProduct?.config?.criteriaValues[0] ?? {}),
              };

              return acc;
            }, {});

            return menuAcc;
          }, {}),
        },
      };

    case SET_MENU_GENERAL_FORM_VALUES:
      return {
        ...state,
        proposalMenu: { ...state.proposalMenu, ...payload },
      };

    case SET_PROPOSALS_PRODUCTS:
      return setProducts(state, payload);

    case SET_SELECTED_PROPOSALS_PRODUCTS:
      return {
        ...state,
        selectedProposalsProducts: payload,
      };

    case SET_MENU_OPTION_TO_ADD_EDIT:
      return {
        ...state,
        menuOptionToAddEdit: payload.currentMenuOption,
      };

    case SET_PARTNER_LINKS_BY_ID:
      return {
        ...state,
        partnerLinksById: payload,
      };

    case ADD_MENU_OPTION:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: [...state.proposalMenu.menuOptions!, payload.menuOption],
        },
      };

    case EDIT_MENU_OPTION:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            [payload.menuOption.name]:
              state.proposalProducts.productsConfigurationInMenu[state.menuOptionToAddEdit.name!],
            ...Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
              (acc: any, [optionName, optionConfig]) => {
                if (optionName === state.menuOptionToAddEdit.name) return acc;

                acc[optionName] = optionConfig;

                return acc;
              },
              {}
            ),
          },
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            return option.ordinal ===
              payload.menuOption.ordinal /* option.proposalMenuOptionId === payload.menuOption.proposalMenuOptionId */
              ? payload.menuOption
              : option;
          }),
        },
      };

    case REMOVE_MENU_OPTION:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
            (acc: any, [optionName, config]) => {
              if (optionName === payload.currentMenuOption.name) return acc;

              acc[optionName] = config;

              return acc;
            },
            {}
          ),
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.filter((option: ProposalMenuOption) => {
            return option !== payload.currentMenuOption;
          }),
        },
        archivedMenuOptions: payload.currentMenuOption.proposalMenuOptionId
          ? [...state.archivedMenuOptions, { ...payload.currentMenuOption, archived: true }]
          : state.archivedMenuOptions,
      };

    case SELECT_MENU_OPTION:
      return {
        ...state,
        selectedMenuOption: payload.menuOption,
      };

    case ADD_MENU_OPTION_PRODUCT:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [payload.menuType]: {
              ...state.proposalProducts.productsConfigurationInMenu?.[payload.menuType],
              [payload.product.proposalProductId]:
                state.proposalProducts.productsConfiguration[payload.product.proposalProductId],
            },
          },
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === payload.menuType) {
              return {
                ...option,
                products: [...option.products, payload.product],
              };
            }

            return option;
          }),
        },
      };

    case REMOVE_MENU_OPTION_PRODUCT:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [payload.menuType]: immutableUtils.removeKeyFromObj(
              state.proposalProducts.productsConfigurationInMenu[payload.menuType],
              payload.productId
            ),
          },
        },
        selectedMenuOption: {
          ...state.selectedMenuOption,
          products: state.selectedMenuOption?.products?.filter(
            product => product?.proposalProductId !== payload.productId
          ),
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === payload.menuType) {
              return {
                ...option,
                products: option.products.filter(product => product?.proposalProductId !== payload.productId),
              };
            }

            return option;
          }),
        },
      };

    case UPDATE_PRODUCT_CONFIGURATION:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            [payload.productId]: {
              ...(state.proposalProducts.productsConfiguration?.[payload.productId] ?? {}),
              ...payload.data,
            },
          },
        },
      };

    case "MENU_CONSTRUCTOR_SWITCH_PRODUCTS":
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions?.reduce((acc: any, option) => {
            if (option.name === payload.menuName) {
              acc.push({
                ...option,
                products: immutableUtils.swapItems(option.products, payload.from, payload.to),
              });
            } else acc.push(option);

            return acc;
          }, []),
        },
      };

    case "MENU_CONSTRUCTOR_ADD_ALL_PRODUCTS_TO_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce<
            MenuConstructorState["proposalProducts"]["productsConfigurationInMenu"]
          >((configurationInMenu, [optionName, configurationByProductId]) => {
            configurationInMenu[optionName] = {
              ...configurationByProductId,
              ...state.selectedProposalsProducts.reduce<Record<string, any>>((acc, product) => {
                if (!configurationByProductId[product.proposalProductId]) {
                  acc[product.proposalProductId] =
                    state.proposalProducts.productsConfiguration[product.proposalProductId];
                }
                return acc;
              }, {}),
            };
            return configurationInMenu;
          }, {}),
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map(option => ({
            ...option,
            products: [
              ...option.products,
              ...state.selectedProposalsProducts.filter(
                ({ proposalProductId }) =>
                  !option.products.map(product => product?.proposalProductId).includes(proposalProductId)
              ),
            ],
          })),
        },
      };

    case UPDATE_PRODUCT_CONFIGURATION_IN_MENU:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [payload.menuName]: {
              ...(state.proposalProducts.productsConfigurationInMenu?.[payload.menuName] ?? {}),
              [payload.productId]: {
                ...(state.proposalProducts.productsConfigurationInMenu?.[payload.menuName]?.[payload.productId] ?? {}),
                ...payload.data,
              },
            },
          },
        },
      };

    case SET_INITIAL_PROPOSALS_MENU:
      return {
        ...state,
        initialProposalMenu: {
          ...payload,
          menuOptions: payload.menuOptions.map((option: any) => ({
            ...option,
            products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
          })),
        },
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case CLEAR:
      return initialState;

    default:
      return state;
  }
};
