import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { ProposalProductsCard } from "modules/desking";
import { ProposalProductCardModes } from "modules/desking/types";
import { useModal } from "hooks/useModal";
import { ModalsKeys } from "global";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";

export const ProductsCard = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;
  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption);
  const allProducts = useSelector(menuConstructorSelectors.proposalsProducts) as ProposalProduct[];
  const selectedProducts = useSelector(menuConstructorSelectors.selectedProposalsProducts) as ProposalProduct[];
  const proposalProductConfigurations = useSelector(menuConstructorSelectors.proposalProductConfigurations);
  const proposalProductConfigurationsInMenu = useSelector(menuConstructorSelectors.proposalProductConfigurationsInMenu);
  const selectedProductsFactsForCheck = useSelector(menuConstructorSelectors.selectedProductsFactsForCheck);
  const selectedProductsFactsSkipped = useSelector(menuConstructorSelectors.selectedProductsFactsSkipped);

  const { handleOpen: handleProposalProductsDialogOpen } = useModal(ModalsKeys.ProposalProductsDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const handleProductsDialogConfirm = (selectedProducts: ProposalProduct[]) => {
    dispatch(menuConstructorActions.setSelectedProducts(selectedProducts));
  };

  const productCardsForEdit = (selectedMenuOption?.products || []).map((product: ProposalProduct) => {
    return (
      <ProposalProductsCard
        type="forEdit"
        product={product}
        key={`forEdit-${product.proposalProductId}`}
        mode={ProposalProductCardModes.Constructor}
        factsToCheck={selectedProductsFactsForCheck[product.proposalProductId]}
        factsSkipped={selectedProductsFactsSkipped[product.proposalProductId]}
        updateProductConfiguration={updateValues => {
          dispatch(
            menuConstructorActions.updateProductConfigurationInMenu({
              data: updateValues,
              menuName: selectedMenuOption.name,
              productId: product.proposalProductId,
            })
          );
        }}
        proposalProductConfiguration={
          proposalProductConfigurationsInMenu?.[selectedMenuOption.name]?.[product.proposalProductId] ?? {}
        }
      />
    );
  });

  const handleAddToAllOptions = () => {
    dispatch(menuConstructorActions.addAllProductsToMenu());
  };

  return (
    <div>
      {isLoading || allProducts.length === 0 ? (
        <LinearProgress />
      ) : (
        <CardContainer
          title="Products"
          isLoading={isLoading || allProducts.length === 0}
          actionBtn={
            hasWriteAccess && (
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  handleProposalProductsDialogOpen({
                    products: allProducts,
                    selectedProposalsProductsIds: selectedProducts?.map(p => p.proposalProductId),
                    onConfirm: handleProductsDialogConfirm,
                  });
                }}
              >
                {!!selectedProducts.length ? "Add more" : "Add product"}
              </Button>
            )
          }
        >
          <Box className={classes.container}>
            {selectedProducts.map(product => (
              <ProposalProductsCard
                type="simple"
                product={product}
                key={product.proposalProductId}
                mode={ProposalProductCardModes.Constructor}
                factsToCheck={
                  selectedProductsFactsForCheck ? selectedProductsFactsForCheck[product.proposalProductId] : undefined
                }
                proposalProductConfiguration={proposalProductConfigurations[product.proposalProductId] ?? {}}
                updateProductConfiguration={updateValues => {
                  dispatch(
                    menuConstructorActions.updateProductConfiguration({
                      data: updateValues,
                      productId: product.proposalProductId,
                    })
                  );
                }}
              />
            ))}

            {!!selectedProducts.length && (
              <Tooltip title="Adds all products & their current configuration to every available menu option in the template">
                <Button variant="outlined" onClick={handleAddToAllOptions}>
                  Apply Product(s) to All Options
                </Button>
              </Tooltip>
            )}

            {(selectedMenuOption?.products || []).map((product: ProposalProduct) => (
              <ProposalProductsCard
                type="forEdit"
                product={product}
                key={`forEdit-${product.proposalProductId}`}
                mode={ProposalProductCardModes.Constructor}
                factsToCheck={selectedProductsFactsForCheck[product.proposalProductId]}
                updateProductConfiguration={updateValues => {
                  dispatch(
                    menuConstructorActions.updateProductConfigurationInMenu({
                      data: updateValues,
                      menuName: selectedMenuOption.name,
                      productId: product.proposalProductId,
                    })
                  );
                }}
                proposalProductConfiguration={
                  proposalProductConfigurationsInMenu?.[selectedMenuOption.name]?.[product.proposalProductId] ?? {}
                }
              />
            ))}
          </Box>
        </CardContainer>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    maxHeight: "800px",
    overflowY: "auto",
    flexDirection: "column",
  },
});
