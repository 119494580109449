import gql from "graphql-tag";

export const CALCULATE_CASH_PAYMENTS = gql`
  query CalculateCashPayments($input: [CalculateMultiplePaymentsInput]) {
    calculateMultiplePayments(input: $input) {
      criteria {
        amountFinanced
        paymentPeriodTiming
        residualPercentage
        salesTaxPercentage
        numberOfAdvancePayments
        downPaymentAmount
        daysToFirstPayment
        startDate
        flatFees {
          fee
          identifier
        }
        markups {
          identifier
          markupPercentage
        }
        menu {
          name
          description
          menuOptions {
            name
            payment {
              paymentAmountPerPeriod
              financeProfit
            }
            description
            titleColor
            titleBgColor
            products {
              title
            }
            ordinal
          }
        }
      }
      paymentOptions {
        paymentAmountPerPeriod
        dealerMarkup
        financeProfit
        paymentAmountPerPeriodWithoutDealerMarkup
        rate
        term
        customerRate
        subsidyAmount
        subsidyPercentage
        costOfDeferredPayments
        menu {
          name
          proposalMenuId
          description
          menuOptions {
            name
            description
            type
            menuOptionDetail
            ordinal
            payment {
              paymentAmountPerPeriod
              financeProfit
              dealerMarkup
              paymentAmountPerPeriodWithoutDealerMarkup
              rate
              term
              customerRate
              subsidyAmount
              subsidyPercentage
              aftermarketProfit
              totalProfit
              profitPercentage
              productCost
              amountDueToday
              costOfDeferredPayments
            }
            titleColor
            titleBgColor
            products {
              cost
              title
              addons {
                category
                cost
                markup {
                  markup
                  type
                }
                retailCost
                thirdPartyId
                title
              }
              markup {
                markup
                type
              }
              criteria
              retailCost
              thirdPartyUrl
              proposalProductId
              aftermarketProfit
              aftermarketProduct {
                aftermarketProductId
                aftermarketVendorApiChannel
                criteria
                productCategory
                productDescriptionExternal
                productDescriptionInternal
                productName
                productType
                createdDateTime
                modifiedDateTime
                config {
                  criteriaValues
                }
                aftermarketProductCatalogDetails {
                  productSummary
                  limitationsOfLiability
                  eligibilityAndInclusions
                  legalDisclaimer
                  paragraphDescription
                  coverageDetails
                }
              }
            }
          }
        }
        term
      }
    }
  }
`;
