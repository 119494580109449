import { gql } from "apollo-boost";

export const GET_PROPOSALS_FOR_HUB_TABLE = gql`
  query ProposalsForHubTable {
    proposals {
      id
      status
      saleType
      lastName
      fullName
      firstName
      businessName
      proposalStage
      salesPersonName
      createdDateTime
      amountRequested
      vendorOpportunityId
      vendorOpportunity {
        applicationStage
        salesRepresentative {
          firstName
          lastName
          fullName
        }
        financeManager {
          firstName
          lastName
          fullName
        }
        location {
          locationName
        }
      }
    }
  }
`;
