import { FinanceProgram, UpdateFinanceProgramInput } from "@trnsact/trnsact-shared-types";
import { FinanceProgramForm } from "../schema";
import { mapConfigurationFormValuesToCreateOrUpdate } from "./mapConfigurationFormValuesToCreateOrUpdate";

export function mapFormValuesToUpdateFinanceProgramInput(
  programToUpdate: FinanceProgram,
  formValue: FinanceProgramForm,
  criteriaId?: string
): Omit<UpdateFinanceProgramInput, "active"> {
  const {
    program: { id, first, second, fourth },
  } = formValue;

  if (!id) throw new Error("id is required");

  const programAvailableForLenders = [...first.lenders, ...first.dealers].map(({ value }) => value);

  return {
    financeProgramId: id,
    nameInternal: first.name,
    prescreenCriteriaIdToLink: criteriaId,
    lenderProfileIdsToAssociate: programAvailableForLenders,
    validDateEnd: second.isValidityDates ? second.endDate : programToUpdate.validDateEnd,
    validDateStart: second.isValidityDates ? second.startDate : programToUpdate.validDateStart,
    paymentOptionsConfiguration: mapConfigurationFormValuesToCreateOrUpdate(formValue),
    insuranceCoverage: fourth.insuranceCoverage,
  };
}
