import React, { useCallback } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { FinanceProgramInsuranceCoverage } from "@trnsact/trnsact-shared-types";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { checkIfQuillValueIsEmpty } from "utils/quillUtils";
import { insuranceLabelsByKey } from "modules/financeProgram/constants";
import { InsuranceLabelsByKey } from "modules/financeProgram/types";

interface Props {
  insuranceCoverage: FinanceProgramInsuranceCoverage;
}

export const InsuranceCoverageCell = ({ insuranceCoverage }: Props) => {
  const { handleOpen, handleClose } = useModal(ModalsKeys.Confirmation);

  const handleModalOpen = () => {
    handleOpen({
      title: "Insurance Coverage Details",
      message: (
        <Grid container spacing={2}>
          {renderDetails()}
        </Grid>
      ),
      actionButtons: (
        <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
          Close
        </Button>
      ),
    });
  };

  const renderDetails = useCallback(
    () =>
      Object.keys(insuranceLabelsByKey).map(key =>
        !checkIfQuillValueIsEmpty(insuranceCoverage[key as keyof InsuranceLabelsByKey]) ? (
          <Grid item xs={12} key={key}>
            <Typography style={{ fontWeight: "bold" }} component="span">
              {insuranceLabelsByKey[key as keyof InsuranceLabelsByKey]}:
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: insuranceCoverage[key as keyof InsuranceLabelsByKey]! }}></div>
          </Grid>
        ) : null
      ),
    [insuranceCoverage]
  );

  const hasInsuranceCoverage =
    !checkIfQuillValueIsEmpty(insuranceCoverage?.coverageRequirements) ||
    !checkIfQuillValueIsEmpty(insuranceCoverage?.certificateRequirements) ||
    !checkIfQuillValueIsEmpty(insuranceCoverage?.otherInstructions);

  if (!hasInsuranceCoverage) {
    return null;
  }

  return (
    <Button size="small" color="primary" variant="text" onClick={handleModalOpen}>
      Details
    </Button>
  );
};
