import React, { PropsWithChildren, useState } from "react";
import { grey } from "@material-ui/core/colors";
import { Add, Remove } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import { SectionStatus } from "../../../../types";

interface Props {
  label: string;
  sectionKey: keyof SectionStatus;
}

export const SummaryContainer = ({ children, sectionKey, label }: PropsWithChildren<Props>) => {
  const classes = useStyles();

  const [sectionStatus, setSectionStatus] = useState<SectionStatus>({
    docs: true,
    details: true,
    criteria: true,
    structure: true,
    insuranceCoverage: true,
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <IconButton
          size="small"
          onClick={() =>
            setSectionStatus(prev => ({
              ...prev,
              [sectionKey]: !prev[sectionKey],
            }))
          }
        >
          {sectionStatus[sectionKey] ? <Remove /> : <Add />}
        </IconButton>

        <Typography component="span" variant="subtitle2">
          {label}
        </Typography>
      </Box>

      {sectionStatus[sectionKey] && <Box>{children}</Box>}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    gap: "8px",
    padding: "4px",
    display: "flex",
    borderRadius: "4px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  content: {},
});
