import React from "react";
import { Box } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyInputField, SelectField } from "components/form";
import { useSaleMode } from "../../../../context";
import { FormSectionHeader } from "../../../components";
import { deskingActions, deskingSelectors } from "../../../../model";
import { defaultCashTerm, saleTypeOptions } from "../../../../constants";
import { BuilderViewType, FinanceProgramFormValues, FormSectionProps, SaleType } from "../../../../types";

export const SaleDetails = ({ sectionName, updateInvoiceAction }: FormSectionProps) => {
  const { onChangeSaleType } = useSaleMode();
  const dispatch = useDispatch();

  const { control, setValue, resetField, getValues } = useFormContext<FinanceProgramFormValues>();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const isSectionCollapsed = useSelector((state: any) => deskingSelectors.isSectionCollapsed(state, sectionName));

  const handleChangeSaleType = (nextSateType: string) => {
    const isCash = nextSateType === SaleType.Cash;

    if (isCash) {
      const currentMenu = getValues("financeQuote.menu");
      resetField("financeQuote");
      setValue("financeQuote.menu", currentMenu);

      dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.AftermarketMenu }));
      dispatch(deskingActions.setMenuTerm({ index: 0, term: defaultCashTerm }));
    } else {
      resetField("financeQuote");
      dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.Finance }));
      dispatch(deskingActions.resetMenuBuilder());
    }

    onChangeSaleType(nextSateType as SaleType);
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Sale Details"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        extraAction={updateInvoiceAction}
        isSectionCollapsed={isSectionCollapsed}
      />

      <Box className="sectionFields">
        <SelectField
          control={control}
          label="Sale Type"
          options={saleTypeOptions}
          name="saleDetails.saleType"
          extraAction={handleChangeSaleType}
        />

        <CurrencyInputField
          control={control}
          label="Equipment Subtotal"
          name="saleDetails.equipmentSubtotal"
          textFieldProps={{ disabled: true }}
        />

        <Box className="row">
          <CurrencyInputField
            control={control}
            label="Sales Tax"
            name="saleDetails.salesTax"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Total Fees"
            name="saleDetails.fees"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Net trade"
            name="saleDetails.netRate"
            textFieldProps={{ disabled: true }}
          />
        </Box>

        <Box className="row">
          <CurrencyInputField
            control={control}
            label="Invoice Total"
            name="saleDetails.invoiceTotal"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Down Payment"
            name="saleDetails.downPayment"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Net finance amount"
            name="saleDetails.financeAmount"
            textFieldProps={{ disabled: true }}
          />
        </Box>
      </Box>
    </Box>
  );
};
