import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  message: string;
}

export const NotificationAlert = ({ message }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.fillAlert}>
      <InfoIcon color="primary" />

      <Typography component="span" variant="subtitle2" color="primary">
        {message}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  fillAlert: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    paddingTop: "0.25rem",
  },
});
