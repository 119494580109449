import React from "react";
import { FormElement } from "./FormElement";
import { useDrag, useDrop } from "react-dnd";
import { ControlTypes } from "../../untils/types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Fab, Grid } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import classNames from "classnames";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Alert from "@material-ui/lab/Alert";

import { RuleTooltipDescription } from "../RuleTooltipDescription/index";
import { ocaSectionTypes } from "../../../../pages/Prequal/constants";

import {
  checkIfCoApplicantSection,
  checkIfDocumentSignSection,
  checkIfDynamicReferenceSection,
  checkIfDynamicSignaturesSection,
  checkIfEquipmentSection,
  checkIfLegacyReferenceSection,
  checkIfMarketingCommunicationSection,
  checkIfPersonalGuaranteeSignaturesSection,
  complexFieldNamesSettersBySectionType,
  isOwnershipSection,
} from "../../../../services/dynamicOCAService";

const useStyles = makeStyles(theme => ({
  section: {
    display: "flex",
    width: "100%",
    minHeight: 200,
    padding: 15,
    marginBottom: 30,
    flexDirection: "column",
    cursor: "move",
    borderRadius: "7px",
  },
  emptySection: {
    backgroundColor: props => (props.isDragging ? theme.palette.primary.main : "#ffcfc752"),
    minHeight: 100,
    border: props => (props.canDrop ? "1px dashed black" : 0),
  },
  filledSection: {
    backgroundColor: props => (props.isDragging ? theme.palette.primary.main : "transparent"),
    border: props => (props.canDrop ? "1px dashed black" : 0),
    boxShadow: "0px 1px 5px 3px rgba(0, 0, 0, 0.12)",
  },
  optionsContainer: {
    position: "relative",
    width: "100%",
    display: "inline-block",
  },
  removeButton: {
    position: "absolute",
    right: "96px",
    top: "8px",
  },
  editButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  hasDependenciesLbl: {
    fontSize: 13,
    color: "#3870ef",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    textDecorationThickness: "2px",
  },
  typedSectionInfoMessage: {
    "& .MuiAlert-root": {
      fontWeight: "bold",
      color: "#2196f3",
    },
  },
  legacySection: {
    "& .MuiAlert-root": {
      color: "#f44336",
    },
  },
}));

export function Section({
  swapFields,
  updateSection,
  swapSections,
  config,
  addSection,
  page,
  index,
  setSelectedItem,
  deleteSection,
  isFormElementMoving,
  setIsFormElementMoving,
  handleFieldInsertion,
  setFormElementMovingSettings,
}) {
  const isOwnersSection = isOwnershipSection(config);
  const isLegacyReferenceSection = checkIfLegacyReferenceSection(config);
  const isDynamicReferenceSection = checkIfDynamicReferenceSection(config);
  const isCoApplicantSection = checkIfCoApplicantSection(config);
  const isDynamicSignaturesSection = checkIfDynamicSignaturesSection(config);
  const isMarketingCommunicationSection = checkIfMarketingCommunicationSection(config);
  const isPersonalGuaranteeSignaturesSection = checkIfPersonalGuaranteeSignaturesSection(config);
  const isDocumentSignSection = checkIfDocumentSignSection(config);
  const isEquipmentSection = checkIfEquipmentSection(config);

  const ownerIndex = _.get(config, "config.ownerIdx");
  const isOwnerRelatedSection = (ownerIndex || ownerIndex === 0) && ownerIndex !== "not-set";

  const [{ isElementOver, isSectionOver, canDrop }, drop] = useDrop(() => {
    return {
      accept: [ControlTypes.CONTROL, ControlTypes.SECTION],
      collect: monitor => {
        return {
          isElementOver: monitor.isOver() && monitor.getItemType() === ControlTypes.ELEMENT,
          isSectionOver: monitor.isOver() && monitor.getItemType() === ControlTypes.SECTION,
          canDrop: monitor.canDrop(),
        };
      },
      canDrop: (item, monitor) => {
        if (item.type === ControlTypes.SECTION) {
          return true;
        }
        if ((!config || !config.title) && item.type !== "title") {
          return false;
        }

        const isSectionTyped = config.sectionType || item.sectionTypes || isOwnersSection;
        if (monitor.getItemType() === ControlTypes.CONTROL && isSectionTyped) {
          let sectionType; // not all sections have sectionType. For legacy ones we need to determine it manually
          switch (true) {
            case isOwnersSection:
              sectionType = "owners-array";
              break;
            default:
              sectionType = config.sectionType;
          }

          if (sectionType) {
            // typed section
            return _.includes(item.sectionTypes, sectionType);
          }

          if (!sectionType) {
            // common section (non typed)
            return _.some(item.sectionTypes) ? _.includes(item.sectionTypes, ocaSectionTypes.common) : true; // for keeping prev behavior working
          }
          return true;
        }

        return true;
      },
      drop: (item, monitor) => {
        if (monitor.getItemType() === ControlTypes.SECTION) {
          swapSections(index, item.index, page);
          return;
        }

        if (monitor.getItemType() === ControlTypes.OWNERS) {
          addSection(
            {
              title: {
                id: uuidv4(),
                type: "owners-array",
                config: {
                  fieldName: "owners",
                  displayLabel: { en: "", es: "" },
                },
              },
              config: {},

              rulesSatisfactionType: "all",
              rules: [],
              fields: [],
            },
            page
          );
          return;
        }

        const configToSet = _.cloneDeep(item.config); // it's better to use cloneDeep here because config may have nested objects (like displayLabel etc.)

        if (complexFieldNamesSettersBySectionType[config.sectionType]) {
          configToSet.fieldName = complexFieldNamesSettersBySectionType[config.sectionType](
            configToSet.fieldName,
            config
          );
        }

        let section = {};

        if (item.type === "title") {
          section = { ...config };
          section.title = { ...item, id: uuidv4() };
          updateSection(section, page);
          return;
        }

        if (!config || !config.title) {
          section.title = { id: uuidv4(), type: "title" };
          section.fields = [];
          section.config = {};
          section.rulesSatisfactionType = "all";
          section.rules = [];
          updateSection(section, page);
          return;
        }

        section = { ...config };
        section.fields = (section.fields || []).concat({
          ...item,
          id: uuidv4(),
          config: configToSet,
        });
        updateSection(section, page);
      },
    };
  }, [config, swapSections]);

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: ControlTypes.SECTION,
      item: {
        index,
      },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    };
  }, [index]);

  const getRef = element => {
    drag(element);
    drop(element);
  };

  const classes = useStyles({ isDragging, canDrop });
  const removeSection = config => {
    //Remove empty section....all good

    if (_.size(config.fields) == 0) {
      deleteSection(index, page);
    } else {
      const response = window.confirm("This section has fields already configured, are you sure?");
      if (response) {
        deleteSection(index, page);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <div
        ref={getRef}
        className={classNames({
          [classes.section]: true,
          [classes.filledSection]: _.size(config.fields) > 0,
          [classes.emptySection]: _.size(config.fields) == 0,
        })}
      >
        {_.size(config.rules) ? (
          <span>
            <RuleTooltipDescription rules={config.rules} rulesSatisfactionType={config.rulesSatisfactionType}>
              <Button className={classes.hasDependenciesLbl}>Dependent Section</Button>
            </RuleTooltipDescription>
          </span>
        ) : null}
        <div className={classes.optionsContainer}>
          <Fab
            color="primary"
            variant="extended"
            aria-label="edit"
            size="small"
            onClick={e => {
              e.preventDefault();
              setSelectedItem({ ...config, extras: { sectionIndex: index, page } });
            }}
            className={classes.editButton}
          >
            <EditIcon /> Edit
          </Fab>
          <Fab
            color="secondary"
            variant="extended"
            aria-label="remove"
            size="small"
            onClick={() => {
              removeSection(config);
            }}
            className={classes.removeButton}
          >
            <DeleteIcon /> Remove
          </Fab>
        </div>
        {isOwnersSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Owners Section
            </Alert>
          </div>
        )}
        {isDynamicReferenceSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Dynamic Reference Section - {config.referenceType}
              {isOwnerRelatedSection && <div>Linked owner - {ownerIndex}</div>}
            </Alert>
          </div>
        )}
        {isLegacyReferenceSection && (
          <div className={[classes.typedSectionInfoMessage, classes.legacySection].join(" ")}>
            <Alert severity="error" variant="outlined">
              Legacy Reference Section
            </Alert>
          </div>
        )}
        {isCoApplicantSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Co-Applicant Section
            </Alert>
          </div>
        )}
        {isDocumentSignSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Document Sign Section
            </Alert>
          </div>
        )}
        {isDynamicSignaturesSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Dynamic Signatures Section
            </Alert>
          </div>
        )}
        {isPersonalGuaranteeSignaturesSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Personal Guarantee Signatures Section
            </Alert>
          </div>
        )}
        {isMarketingCommunicationSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Marketing Communication Authorization Section
            </Alert>
          </div>
        )}
        {isEquipmentSection && (
          <div className={classes.typedSectionInfoMessage}>
            <Alert severity="info" variant="outlined">
              Equipment Section - {config.equipmentType}
            </Alert>
          </div>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {config && config.title && (
            <h3 id={config.title.id} style={config.title.config} onClick={() => setSelectedItem(config.title)}>
              {_.get(config, `title.config.displayLabel.en`, "")}
            </h3>
          )}
        </Grid>
        {_.size(config.fields) > 0 ? (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={3}>
              {config &&
                config.fields.map((field, i) => {
                  return (
                    <FormElement
                      item={field}
                      key={i}
                      index={i}
                      onDrop={swapFields}
                      page={page}
                      sectionIndex={index}
                      setSelectedItem={setSelectedItem}
                      isFormElementMoving={isFormElementMoving}
                      setIsFormElementMoving={setIsFormElementMoving}
                      handleFieldInsertion={handleFieldInsertion}
                      setFormElementMovingSettings={setFormElementMovingSettings}
                    />
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          <span>Block has no fields yet</span>
        )}
      </div>
    </Grid>
  );
}
