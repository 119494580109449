import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useModal } from "hooks/useModal";
import AddIcon from "@material-ui/icons/Add";
import { ModalsKeys, SelectOption } from "global";
import { FormInputSelect } from "components/form";
import { makeStyles } from "@material-ui/core/styles";
import { batch, useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { useSaleMode } from "../../../context";
import { defaultCashTerm } from "../../../constants";
import { BuilderViewType, FinanceProgramFormValues } from "../../../types";
import { sortEntitiesByModifiedDate } from "../../../lib";
import { GET_PROPOSAL_MENUS_SELECT_OPTIONS } from "../../../api";
import { deskingActions, deskingSelectors } from "../../../model";
import { accountSelectors } from "../../../../../redux/accountReducer";
import { MenuSelectsResponse, MenuSelectsVariables } from "../../../api/types";

interface Props {
  withAddProductsFeature?: boolean;
}

export const MenuSelector = ({ withAddProductsFeature = true }: Props) => {
  const classes = useStyles();

  const [menuSelectsOptions, setMenuSelectsOptions] = useState<SelectOption[]>([]);

  const { isCash } = useSaleMode();

  const { handleOpen: onOpenProposalProductsDialog } = useModal(ModalsKeys.ProposalProductsDialog);
  const { handleOpen } = useModal(ModalsKeys.DeskingNotifyAboutRemovedProductsDialog);

  const { control, setValue, getValues, resetField } = useFormContext<FinanceProgramFormValues>();
  const menu = useWatch({ control, name: "financeQuote.menu" });

  const dispatch = useDispatch();

  const accountId = useSelector(accountSelectors.accountId);
  const proposalMenus = useSelector(deskingSelectors.proposalMenus);
  const products = useSelector(deskingSelectors.proposalsProducts);
  const isProposalsProductsLoading = useSelector<any, boolean>(deskingSelectors.isProposalsProductsLoading);
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray);
  const selectedProposalsProductsIds = useSelector(deskingSelectors.selectedProposalsProductsIds);
  const proposalsProductsIds = useSelector<any, string[]>(deskingSelectors.proposalsProductsIds);

  useQuery<MenuSelectsResponse, MenuSelectsVariables>(GET_PROPOSAL_MENUS_SELECT_OPTIONS, {
    skip: !accountId,
    variables: { accountId },
    onCompleted(data) {
      const optionsWithDateAndTime = data.proposalMenus;

      const options = sortEntitiesByModifiedDate(optionsWithDateAndTime).map(
        ({ modifiedDateTime, ...option }) => option
      );

      setMenuSelectsOptions(options);
    },
  });

  const handleChangeMenu = (nextValue: string) => {
    setValue("financeQuote.menu", nextValue);
    resetField("displaySettings");

    const financeProgramTerms = isCash ? [defaultCashTerm].map(Number) : getValues("financeQuote.terms").map(Number);

    dispatch(
      deskingActions.setMenuToBuilder({
        id: nextValue,
        terms: financeProgramTerms.map(String),
      })
    );

    const maxTerm = Math.max(...financeProgramTerms);

    batch(() => {
      dispatch(deskingActions.toggleIsEditEquipmentStatus({ status: true }));
      dispatch(deskingActions.setMenuTerm({ term: String(maxTerm), index: financeProgramTerms.lastIndexOf(maxTerm) }));
      dispatch(
        deskingActions.setProductsConfigFromExistingMenu({
          terms: financeProgramTerms.map(String),
          data: proposalMenus[nextValue].menuOptions,
        })
      );
      dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.AftermarketMenu }));
    });

    const products = (proposalMenus[nextValue].menuOptions.map((option: any) => option?.products)?.flat() ??
      []) as ProposalProduct[];

    const productsRemovedFromMenu = products.reduce<ProposalProduct[]>((acc, product) => {
      if (
        !proposalsProductsIds.includes(product.proposalProductId) &&
        !products.find(p => p.aftermarketProduct?.externalId === product.aftermarketProduct?.externalId)
      ) {
        acc.push(product as ProposalProduct);
      }
      return acc;
    }, []);

    if (productsRemovedFromMenu.length) {
      handleOpen({ removedProducts: productsRemovedFromMenu });
      dispatch(
        deskingActions.removeProductsFromMenu({
          ids: productsRemovedFromMenu.map(({ proposalProductId }) => proposalProductId),
        })
      );
    }
  };

  const handleProductsDialogConfirm = (selectedProducts: any) => {
    dispatch(deskingActions.updateProducts({ products: selectedProducts }));
  };

  return (
    <Box className={classes.container}>
      <Controller
        control={control}
        name="financeQuote.menu"
        render={({ field }) => (
          <FormInputSelect
            variant="standard"
            label="Select Menu Template"
            options={menuSelectsOptions}
            isLoading={isProposalsProductsLoading}
            disabled={isProposalsProductsLoading || !menuSelectsOptions.length}
            {...field}
            onChange={handleChangeMenu}
          />
        )}
      />

      {withAddProductsFeature && menu && (
        <Button
          startIcon={<AddIcon />}
          disabled={isProposalsProductsLoading}
          onClick={() => {
            onOpenProposalProductsDialog({
              products,
              selectedProposalsProductsIds,
              onConfirm: handleProductsDialogConfirm,
            });
          }}
        >
          {!!selectedProducts?.length ? "Add more" : "Add product"}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    alignItems: "center",

    "& > button.MuiButton-root": {
      minWidth: "200px",
    },
  },
});
