import React from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";

interface Props {
  menuOption: any;
}

export const MenuOptionCardDeclination = ({ menuOption }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <BlockIcon className={classes.blockIcon} />
        <Typography variant="h6">DECLINE COVERAGE</Typography>
      </Box>
      <Chip label="NONE" className={classes.chip} />
      <Typography>Choose Another Option to Protect your Asset</Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    padding: "5px 10px",
    backgroundColor: "#f5f5f5",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  blockIcon: {
    color: "red",
    marginRight: 4,
  },
  chip: {
    margin: "10px 0",
  },
}));
