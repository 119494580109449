import React, { useMemo, useRef } from "react";
import clsx from "clsx";
import { Nullable } from "global";
import { formatCurrency } from "utils";
import { useDrag, useDrop } from "react-dnd";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { CardPriceBlock } from "../cardPriceBlock";
import { MenuOptionCardHeader } from "./MenuOptionCardHeader";
import { MenuOptionCardFooter } from "./MenuOptionCardFooter";
import { MenuOptionCardBody } from "./menuOptionCardBody/MenuOptionCardBody";
import {
  AftermarketMenuOption,
  CommonMenuPriceValues,
  DnDTypes,
  MarkupType,
  ProductConfig,
  ProposalProductCardModes,
  SaleType,
} from "../../../types";

interface Props {
  menuOption: any;
  saleType?: SaleType;
  menuOptionIdx: number;
  isCalculating: boolean;
  mode?: ProposalProductCardModes;
  onSelectMenuOption?: (optionName: string) => void;
  selectedMenuOption?: Nullable<AftermarketMenuOption>;
  productsConfiguration?: Record<string, ProductConfig>;
  moveMenuOption: (dragIndex: number, hoverIndex: number) => void;
  onOpenProductDetailsDialog?: (product: ProposalProduct) => void;
  onEditOption?: (currentMenuOption: any) => void;
  onRemoveOption?: (currentMenuOption: any) => void;
  onDropProduct?: (product: ProposalProduct, menuOptionName: string) => void;
  onSwitchProducts: (options: { menuName: string; from: number; to: number }) => void;
  onRemoveAddon?: (product: ProposalProduct, optionName: string, title: string) => void;
  onRemoveProduct?: (productId: string, currentMenuOption: AftermarketMenuOption) => void;
}

export const MenuOptionCard = ({
  saleType,
  menuOption,
  menuOptionIdx,
  isCalculating,
  onEditOption,
  onRemoveOption,
  onDropProduct,
  onRemoveAddon,
  onRemoveProduct,
  moveMenuOption,
  onSwitchProducts,
  selectedMenuOption,
  onSelectMenuOption,
  onOpenProductDetailsDialog,
  productsConfiguration = {},
  mode = ProposalProductCardModes.Desking,
}: Props) => {
  const classes = useStyles({
    isSelectable: !!onSelectMenuOption,
  });

  const menuOptionRef = useRef<HTMLDivElement>(null);

  const commonPrice = useMemo(
    () =>
      (menuOption as AftermarketMenuOption).products.reduce<CommonMenuPriceValues>(
        (pricing, { proposalProductId }) => {
          const config = productsConfiguration[proposalProductId];

          pricing.cost += +config?.cost;
          pricing.markup.markup += +config?.retailCost - +config?.cost;
          pricing.markup.type = MarkupType.Flat;
          pricing.retailCost += +config?.retailCost;

          return pricing;
        },
        { cost: 0, markup: { markup: 0, type: null }, retailCost: 0 }
      ),
    [menuOption?.products, productsConfiguration]
  );

  const [{ isOver }, productsDropContainer] = useDrop<ProposalProduct, void, { isOver: boolean }>(
    () => ({
      accept: DnDTypes.ProposalProduct,
      drop: product => onDropProduct!(product, menuOption.name),
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDropProduct, menuOption]
  );

  const [{ handlerId }, menuOptionDropContainer] = useDrop(
    () => ({
      accept: DnDTypes.MenuOption,
      collect: monitor => ({
        handlerId: monitor.getHandlerId(),
      }),
      hover(item: any, monitor) {
        if (!menuOptionRef.current) {
          return;
        }
        const dragIndex = item.menuOptionIdx;
        const hoverIndex = menuOptionIdx;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = menuOptionRef.current?.getBoundingClientRect();
        // Get horizontal middle
        const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the right
        const hoverClientX = clientOffset!.x - hoverBoundingRect.right;
        // Only perform the move when the mouse has crossed half of the items width
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
        // Time to actually perform the action
        moveMenuOption(dragIndex, hoverIndex);
        item.menuOptionIdx = hoverIndex;
      },
    }),
    [moveMenuOption]
  );

  const [{ isMenuOptionDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.MenuOption,
      item: { menuOptionIdx },
      collect: monitor => ({
        isMenuOptionDragging: monitor.isDragging(),
      }),
    }),
    [menuOptionIdx]
  );

  drag(menuOptionDropContainer(menuOptionRef));

  return (
    <Box
      className={clsx(classes.card, {
        [classes.overCard]: isOver,
        [classes.draggingCard]: isMenuOptionDragging,
        [classes.selectedCard]: menuOption.name === selectedMenuOption?.name,
        [classes.selectedOverCard]: isOver && menuOption.name === selectedMenuOption?.name,
      })}
      onClick={() => {
        if (onSelectMenuOption) onSelectMenuOption(menuOption.name);
      }}
      {...({ ref: menuOptionRef } as any)}
      data-handler-id={handlerId}
    >
      <MenuOptionCardHeader menuOption={menuOption} onEditOption={onEditOption} onRemoveOption={onRemoveOption} />

      <MenuOptionCardBody
        mode={mode}
        menuOption={menuOption}
        commonPrice={commonPrice}
        onRemoveAddon={onRemoveAddon}
        onRemoveProduct={onRemoveProduct}
        onSwitchProducts={onSwitchProducts}
        productsConfiguration={productsConfiguration}
        productsDropContainer={productsDropContainer}
        onOpenProductDetailsDialog={onOpenProductDetailsDialog}
      />

      <CardPriceBlock type="withBg" isTotal {...commonPrice} />

      <MenuOptionCardFooter
        saleType={saleType}
        isLoading={isCalculating}
        prices={{
          [SaleType.Cash]: [{ value: formatCurrency(commonPrice.retailCost, true) }],
          [SaleType.Financing]: [
            { label: "DUE IN ADVANCE", value: formatCurrency(menuOption?.payment?.amountDueToday) },
            { label: "PER MONTH", value: formatCurrency(menuOption?.payment?.paymentAmountPerPeriod) },
          ],
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isSelectable: boolean }>(({ palette }) => ({
  card: {
    gap: "8px",
    padding: "4px",
    display: "flex",
    minHeight: "260px",
    minWidth: "300px",
    width: "300px",
    flexDirection: "column",
    border: "2px dashed lightgrey",
    justifyContent: "space-between",
    cursor: ({ isSelectable }) => (isSelectable ? "pointer" : "default"),
  },
  overCard: {
    border: "2px solid lightgrey",
  },
  selectedCard: {
    border: `2px dashed ${palette.primary.main}`,
  },
  selectedOverCard: {
    border: `2px solid ${palette.primary.main}`,
  },
  draggingCard: {
    opacity: 0,
  },
}));
