import { ProposalMenu, ProposalMenuOption, ProposalMenuOptionType } from "@trnsact/trnsact-shared-types";

export type ProductId = string;

export enum AftermarketPageTabs {
  Products = "Products",
  MenuTemplates = "Menu Templates",
  Dashboard = "Dashboard",
}

export interface MenuTemplatesTableFilters {
  search: string;
}

export interface MenuOptionCardsProps {
  menuOptions?: ProposalMenuOption[];
  isCalculating: boolean;
}

export enum ProposalMenuSearchableColumnsEnum {
  Name = "name",
  Description = "description",
}
export enum MenuOptionColorPickerKeysEnum {
  TitleColor = "titleColor",
  TitleBgColor = "titleBgColor",
}

export type ProposalMenuSearchableColumns =
  | ProposalMenuSearchableColumnsEnum.Name
  | ProposalMenuSearchableColumnsEnum.Description;

export type AddEditMenuTemplateUrlParams = {
  proposalMenuId: string;
};

export interface MenuTemplateGeneralFormInputs {
  name: string;
  description: string;
  partnerLinksIdsToShareMenu: string[];
  linkedToCriterias: MenuTemplatePartnerLinkConfiguration[];
}

export interface MenuTemplatePartnerLinkConfiguration {
  partnerLinkId: string;
  isReadOnly: boolean;
  //locationIds: string[];  // will be added in future
}

export interface MenuTemplateSaveAsFormInputs {
  name: string;
  description: string;
}

export interface AddEditMenuOptionFormInputs {
  name: string;
  description: string;
  type: ProposalMenuOptionType;
}

export type MenuOptionToAddEdit = Partial<ProposalMenuOption> & { description: string; archived: boolean };

export type MenuOptionColorPickerKeys =
  | MenuOptionColorPickerKeysEnum.TitleColor
  | MenuOptionColorPickerKeysEnum.TitleBgColor;

export type AftermarketSaveAsDialogData = {
  onSave: (menuToSave: ProposalMenu) => void;
};

export type AftermarketShareMenuConfirmationDialogData = {
  onSave: VoidFunction;
  formData: MenuTemplateGeneralFormInputs;
};
