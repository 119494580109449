import _ from "lodash";
import { AftermarketMenuOption, OptionName, ProductConfig, ProductId } from "../types";

export function prepareMenuOptionsForAPI(
  menuOptions: AftermarketMenuOption[],
  financeProgramDetails: Record<string, any>,
  productsConfigInMenuForFirstTerm: Record<OptionName, Record<ProductId, ProductConfig>>
) {
  if (!menuOptions) return null;

  return menuOptions?.map(menuOption => ({
    name: menuOption.name,
    description: menuOption.description,
    titleColor: menuOption.titleColor,
    titleBgColor: menuOption.titleBgColor,
    type: menuOption.type,
    products: menuOption.products
      .map(product => {
        const productConfig = productsConfigInMenuForFirstTerm?.[menuOption.name]?.[product.proposalProductId];

        if (!productConfig) return null;

        const aftermarketProduct = product.aftermarketProduct ? { ...product.aftermarketProduct } : undefined;

        if (product.markup) {
          delete product.markup.__typename;
          product.markup.markup = _.round(product.markup.markup, 2);
        }

        if (aftermarketProduct && aftermarketProduct.__typename) {
          delete aftermarketProduct.productDescriptionInternal;
          delete aftermarketProduct.createdDateTime;
          delete aftermarketProduct.modifiedDateTime;
          delete aftermarketProduct.__typename;

          if (aftermarketProduct && aftermarketProduct.aftermarketProductCatalogDetails) {
            delete aftermarketProduct.aftermarketProductCatalogDetails.__typename;
          }

          if (aftermarketProduct && aftermarketProduct.config) {
            delete aftermarketProduct.config.__typename;
          }
        }

        const addonsToUse = productConfig?.addons ?? product?.addons ?? [];

        const addonsToSend = addonsToUse.map((addon: any) => {
          const addonToSend = { ...addon };
          delete addonToSend.value;
          return addonToSend;
        });

        return {
          title: product.title,
          cost: Number(productConfig?.cost ?? 0),
          retailCost: Number(productConfig?.retailCost ?? 0),
          thirdPartyId: (product as any).thirdPartyId,
          proposalProductId: product.proposalProductId,
          markup: productConfig
            ? {
                markup: Number(productConfig?.markup?.markup ?? 0),
                type: productConfig?.markup?.type ?? "FLAT",
              }
            : {
                markup: product?.markup?.markup,
                type: product?.markup?.type,
              },
          aftermarketProductToPartnerLinkId: product.aftermarketProductToPartnerLinkId,
          addons: addonsToSend,
          aftermarketProduct,
        };
      })
      .filter(Boolean),
    menuOptionDetail:
      financeProgramDetails && financeProgramDetails[menuOption.name] ? financeProgramDetails[menuOption.name] : [],
  }));
}
