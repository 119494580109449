import React, { CSSProperties, ReactNode } from "react";
import { Add, Remove } from "@material-ui/icons";
import { Box, IconButton, Typography } from "@material-ui/core";
import { FormSectionProps } from "../../types";

interface Props extends Pick<FormSectionProps, "sectionName" | "handleUpdateCollapse" | "handleUpdateOpenStatus"> {
  name: string;
  sx?: CSSProperties;
  isSectionOpen: boolean;
  extraAction?: ReactNode;
  isSectionCollapsed: boolean;
}

export const FormSectionHeader = ({
  name,
  sectionName,
  extraAction,
  isSectionOpen,
  isSectionCollapsed,
  handleUpdateCollapse,
  handleUpdateOpenStatus,
  sx = {},
}: Props) => (
  <Box style={sx} className="sectionHeader">
    <Box className="collapse">
      {!!handleUpdateOpenStatus && (
        <IconButton size="small" onClick={() => handleUpdateOpenStatus(sectionName, !isSectionOpen)}>
          {isSectionOpen ? <Remove /> : <Add />}
        </IconButton>
      )}

      {!!handleUpdateCollapse && (
        <IconButton size="small" onClick={() => handleUpdateCollapse(sectionName, !isSectionCollapsed)}>
          {isSectionCollapsed ? <Add /> : <Remove />}
        </IconButton>
      )}

      {name && (
        <Typography component="span" variant="subtitle2">
          {name}
        </Typography>
      )}
    </Box>

    {extraAction && extraAction}
  </Box>
);
