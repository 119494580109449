import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { ValueOf } from "global";
import { useQuery } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { FieldValue } from "react-hook-form/dist/types/fields";
import { Box, Button } from "@material-ui/core";
import { BuilderCards } from "./BuilderCards";
import { useSaleMode } from "../../../../../context";
import { useCalculations } from "../../../../../hooks";
import { GET_PROPOSAL_MENUS } from "../../../../../api";
import { ProposalMenusResponse } from "../../../../../api/types";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { FinanceProgramFormValues, FormSectionProps, SaleStructureType } from "../../../../../types";
import { FinanceFieldSelector } from "../../../../components/finance/FinanceFieldSelector";
import { FormSectionHeader, MenuSelector, NotificationAlert } from "../../../../components";

export const MenuBuilder = ({
  sectionName,
  handleUpdateCollapse,
  onClearStructureAndMenuOptions,
}: FormSectionProps) => {
  const classes = useStyles();

  const { isCash, onChangeStructureType } = useSaleMode();

  const { control, watch } = useFormContext<FinanceProgramFormValues>();
  const [financeQuote, saleDetails] = useWatch({
    control,
    name: ["financeQuote", "saleDetails"],
  });

  const dispatch = useDispatch();
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const isSectionCollapsed = useSelector((state: any) => deskingSelectors.isSectionCollapsed(state, sectionName));

  const { isCalculating } = useCalculations();

  useQuery<ProposalMenusResponse>(GET_PROPOSAL_MENUS, {
    onCompleted(response) {
      if (!Array.isArray(response?.proposalMenus)) return;
      dispatch(deskingActions.setProposalMenus({ menuOptions: response.proposalMenus }));
    },
  });

  useEffect(() => {
    const formRefetchFields: FieldValue<ValueOf<FinanceProgramFormValues, "financeQuote" | "saleDetails">>[] = [
      "financeQuote.customTermTemporaryValue",
      "financeQuote.terms",
      "financeQuote.program",
      "financeQuote.commonRate",
      "saleDetails.financeAmount",
    ];

    const subscription = watch((value, { name }) => {
      if (!formRefetchFields.includes(name)) return;
      dispatch(deskingActions.updateRecalculate());
    });

    return subscription.unsubscribe;
  }, []);

  const isShowMenuBuilder = useMemo(() => {
    if (isCash) return true;

    const isTermExist = !!financeQuote.terms?.length || !!financeQuote.customTermTemporaryValue;
    const isFinanceAmountExist = !!saleDetails.financeAmount;

    return isFinanceAmountExist && isTermExist;
  }, [financeQuote, isCash]);

  return (
    <Box className={clsx("section", classes.sectionContainer)}>
      <FormSectionHeader
        name="Menu Builder"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        isSectionCollapsed={isSectionCollapsed}
        handleUpdateCollapse={(section, nextValue) => {
          onChangeStructureType(SaleStructureType.AftermarketMenu);
          handleUpdateCollapse?.(section, nextValue);
        }}
        extraAction={
          <>
            {!isSectionCollapsed && (
              <Box className={classes.actionBtn}>
                <Button size="small" color="primary" variant="contained" onClick={onClearStructureAndMenuOptions}>
                  Reset
                </Button>
              </Box>
            )}
          </>
        }
      />

      {!isSectionCollapsed && (
        <Box className="row">
          <FinanceFieldSelector />

          <MenuSelector withAddProductsFeature={false} />
        </Box>
      )}

      {!isShowMenuBuilder && (
        <NotificationAlert message="Missing Finance Inputs. Add Amount, Term and Buy Rate to render." />
      )}

      <Box className={clsx("sectionFields")}>
        {isShowMenuBuilder && (
          <BuilderCards
            isSectionOpen={isSectionOpen}
            isCalculating={isCalculating}
            isMAALessThenFinanceAmount={(financeQuote.maxApprovalAmount ?? 0) < saleDetails.financeAmount}
          />
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  sectionContainer: {
    overflowX: "auto",
  },
  sectionFieldsLoading: {
    height: "100%",
    minHeight: "10rem",
    alignItems: "center",
    justifyContent: "center",
  },
  actionBtn: {
    top: "50%",
    right: "10px",
    position: "absolute",
    transform: "translateY(-50%)",
  },
});
