import { AllRules, FinanceProgramFormSteps, InsuranceLabelsByKey, MarkupMethods, Structures } from "../types";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";
import { convertEnumToSelectOptions, convertFactToSelectOptions } from "../lib";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FinanceProgramForm } from "../schema";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { SelectOption } from "../../../global";
import { TextFieldProps } from "@material-ui/core";
import {
  CANADA_REGION_CODES,
  EQUIPMENT_CONDITION,
  FINANCING_TYPE,
  LEGAL_STRUCTURE,
  US_REGION_CODES,
  FinanceProgramType,
  PaymentPeriodTiming,
  FinanceProgramRateType,
} from "@trnsact/trnsact-shared-types";

export const rateTypeOptions = [
  { label: "Interest Rate", value: FinanceProgramRateType.InterestRate },
  { label: "Rate Factor", value: FinanceProgramRateType.RateFactor },
];

export const structureOptions = [
  { label: "Markup", value: Structures.Markup },
  { label: "Subsidy", value: Structures.Subsidy },
  { label: "None", value: Structures.None },
];

export const markupOptions = [
  { label: "Markup %", value: MarkupMethods.Markup },
  { label: "Customer Rate", value: MarkupMethods.CustomerRate },
];

export const programTypeOptions = [
  { label: "Loan", value: FinanceProgramType.Loan },
  { label: "Lease", value: FinanceProgramType.Lease },
  { label: "EFA", value: FinanceProgramType.Efa },
];

export const paymentCalculationMethodOptions = [
  { label: "Advance", value: PaymentPeriodTiming.BeginningOfPeriod },
  { label: "Arrears", value: PaymentPeriodTiming.EndOfPeriod },
];

export const termsOptions = [
  { label: "12", value: "12" },
  { label: "24", value: "24" },
  { label: "36", value: "36" },
  { label: "48", value: "48" },
  { label: "60", value: "60" },
  { label: "72", value: "72" },
  { label: "84", value: "84" },
  { label: "96", value: "96" },
  { label: "108", value: "108" },
  { label: "120", value: "120" },
];

export const booleanOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const getInitialCriteriaConditionFormValue = (rule: AllRules) => ({
  rule,
  number: { min: null, max: null },
  boolean: { value: false },
  string: { value: [] },
  isRequiredToQuote: false,
});

export const factNamesNumeric = convertFactToSelectOptions(LENDER_WATERFALL_FACT_NAMES_NUMERIC);
export const factNamesBoolean = convertFactToSelectOptions(LENDER_WATERFALL_FACT_NAMES_BOOLEAN);
export const factNamesString = convertFactToSelectOptions(LENDER_WATERFALL_FACT_NAMES_STRING);

export const allFacts = [...factNamesNumeric, ...factNamesString, ...factNamesBoolean];

export const triggerPathByStep: Record<FinanceProgramFormSteps, UseControllerProps<FinanceProgramForm>["name"]> = {
  [FinanceProgramFormSteps.First]: "program.first",
  [FinanceProgramFormSteps.Second]: "program.second",
  [FinanceProgramFormSteps.Third]: "program.third",
  [FinanceProgramFormSteps.Fourth]: "program.fourth",
  [FinanceProgramFormSteps.Fifth]: "program.fifth",
};

const equipmentConditionOptions = convertEnumToSelectOptions(EQUIPMENT_CONDITION);
const financingTypeOptions = convertEnumToSelectOptions(FINANCING_TYPE);
const legalStructureOptions = convertEnumToSelectOptions(LEGAL_STRUCTURE);
const canadaRegionOptions = convertEnumToSelectOptions(CANADA_REGION_CODES);
const usRegionOptions = convertEnumToSelectOptions(US_REGION_CODES);

export const allStringFactsOptions = [
  ...equipmentConditionOptions,
  ...financingTypeOptions,
  ...legalStructureOptions,
  ...canadaRegionOptions,
  ...usRegionOptions,
];

export const autocompletePropsByType: Partial<Record<
  LENDER_WATERFALL_FACT_NAMES_STRING,
  Omit<AutocompleteProps<SelectOption, true, false, false>, "renderInput"> & {
    textFieldProps?: Partial<TextFieldProps>;
  }
>> = {
  [LENDER_WATERFALL_FACT_NAMES_STRING.EQUIPMENT_CONDITION]: {
    textFieldProps: {
      required: true,
      label: "Select the Equipment Condition",
    },
    options: equipmentConditionOptions,
  },
  [LENDER_WATERFALL_FACT_NAMES_STRING.FINANCING_TYPE]: {
    textFieldProps: {
      required: true,
      label: "Select a Financing Type",
    },
    options: financingTypeOptions,
  },
  [LENDER_WATERFALL_FACT_NAMES_STRING.LEGAL_STRUCTURE]: {
    textFieldProps: {
      required: true,
      label: "Select a Legal Structure",
    },
    options: legalStructureOptions,
  },
  [LENDER_WATERFALL_FACT_NAMES_STRING.CANADA_REGION]: {
    textFieldProps: {
      required: true,
      label: "Select a Region",
    },
    options: canadaRegionOptions,
  },
  [LENDER_WATERFALL_FACT_NAMES_STRING.USA_STATE]: {
    textFieldProps: {
      required: true,
      label: "Select a State",
    },
    options: usRegionOptions,
  },
};

export const dialogTitleByFormStep: Record<FinanceProgramFormSteps, string> = {
  [FinanceProgramFormSteps.First]: "Program Details",
  [FinanceProgramFormSteps.Second]: "Configure Structure",
  [FinanceProgramFormSteps.Third]: "Choose Criteria (Optional)",
  [FinanceProgramFormSteps.Fourth]: "Insurance Coverage Requirements",
  [FinanceProgramFormSteps.Fifth]: "Create Finance Program?",
};

export const insuranceLabelsByKey: InsuranceLabelsByKey = {
  coverageRequirements: "Coverage Requirements",
  certificateRequirements: "Certificate Requirements",
  otherInstructions: "Other Instructions",
};
