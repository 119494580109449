import { SelectOption } from "global";
import {
  CreateProposalFinanceExistingOptions,
  MarkupMethods,
  MarkupType,
  PaymentCalculationMethods,
  ProgramTypes,
  RateTypes,
} from "../types";

export const rateTypeOptions: SelectOption<RateTypes>[] = [
  { label: "Interest Rate", value: RateTypes.InterestRate },
  { label: "Rate Factor", value: RateTypes.RateFactor },
];

export const markupOptions: SelectOption<MarkupMethods>[] = [
  { label: "Markup $", value: MarkupMethods.MarkupDollar },
  { label: "Markup %", value: MarkupMethods.MarkupPercentage },
  { label: "Customer Rate", value: MarkupMethods.CustomerRate },
];

export const programTypeOptions: SelectOption<ProgramTypes>[] = [
  { label: "Loan", value: ProgramTypes.Loan },
  { label: "Lease", value: ProgramTypes.Lease },
  { label: "EFA", value: ProgramTypes.EFA },
];

export const paymentCalculationMethodOptions: SelectOption<PaymentCalculationMethods>[] = [
  { label: "Advance", value: PaymentCalculationMethods.Advance },
  { label: "Arrears", value: PaymentCalculationMethods.Arrears },
];

export const termsOptions: SelectOption[] = new Array(10).fill("").map((value, index) => {
  const optionValue = String((index + 1) * 12);

  return { label: optionValue, value: optionValue };
});

export const markupProposalOptions: SelectOption<MarkupType>[] = [
  { label: "Subsidy Amount ($)", value: MarkupType.Flat },
  { label: "Customer Subsidized Rate (%)", value: MarkupType.Percentage },
];

export const markupTypesOptions: SelectOption<MarkupType>[] = [
  { label: "$", value: MarkupType.Flat },
  { label: "%", value: MarkupType.Percentage },
];

export const existingProgramTypeOptions: SelectOption<CreateProposalFinanceExistingOptions>[] = [
  { label: "Yes", value: CreateProposalFinanceExistingOptions.Yes },
  { label: "No", value: CreateProposalFinanceExistingOptions.No },
];

export const maxApprovalGreteThenNetFinance = "Finance Amount exceeds Approval Amount";
export const maxApprovalGreteThenNetFinanceTooltip =
  "Proposal options will be calculated to account for this and increase the Amount Due in Advance";
export const maxApprovalLessOrEqualThenNetFinance = "Finance Amount less than Approval Amount";
