import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { vendorsLogoByType } from "../../../../constants";
import { ProposalProductCard } from "../ProposalProductCard";
import { AftermarketProductTypes, MenuOptionCardBodyProps } from "../../../../types";

export const MenuOptionCardCommon = ({
  mode,
  menuOption,
  onRemoveAddon,
  onRemoveProduct,
  onSwitchProducts,
  productsDropContainer,
  onOpenProductDetailsDialog,
  productsConfiguration = {},
}: MenuOptionCardBodyProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.dnd} {...({ ref: productsDropContainer } as any)}>
        {!menuOption?.products.length ? (
          <Typography component="span" variant="body1" color="textSecondary">
            Drag & drop options here
          </Typography>
        ) : (
          <Box className={classes.proposalProductCards}>
            {menuOption?.products.length &&
              menuOption?.products.map((product: any, index: number) => (
                <ProposalProductCard
                  key={index}
                  mode={mode}
                  index={index}
                  product={product}
                  onOpenProductDetailsDialog={() => onOpenProductDetailsDialog?.(product)}
                  productConfiguration={productsConfiguration?.[product.proposalProductId]}
                  onRemoveAddon={title => onRemoveAddon?.(product, menuOption.name, title)}
                  onSwitchProducts={(from, to) => onSwitchProducts({ menuName: menuOption.name, from, to })}
                  onRemoveProduct={() => onRemoveProduct?.(product.proposalProductId, menuOption)}
                  logo={
                    vendorsLogoByType?.[
                      product?.aftermarketProduct?.aftermarketVendorApiChannel as AftermarketProductTypes
                    ]
                  }
                />
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  dnd: {
    flex: 1,
    display: "flex",
    padding: "4px 0",
    alignItems: "center",
    justifyContent: "center",
  },
  proposalProductCards: {
    gap: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));
