import React, { FC } from "react";
import { FieldNotify, Variants } from "global";
import { Info, Warning, Error } from "@material-ui/icons";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";
import { Box, Typography, Tooltip, TypographyProps } from "@material-ui/core";

export const FormNotificationBlock = ({
  message,
  iconTooltip,
  hide = false,
  iconProps = {},
  withIcon = true,
  variant = "info",
  typographyProps = {},
}: FieldNotify) => {
  if (hide) return null;

  const defaultRenderConfiguration: Record<
    Variants,
    { component: FC<SvgIconProps>; props?: SvgIconProps; typographyProps?: TypographyProps }
  > = {
    info: {
      component: Info,
      props: {
        color: "inherit",
      },
      typographyProps: {
        color: "textSecondary",
      },
    },
    error: {
      component: Error,
      props: {
        color: "error",
      },
      typographyProps: {
        color: "error",
      },
    },
    warning: {
      component: Warning,
    },
    notification: {
      component: Info,
      props: {
        color: "inherit",
      },
      typographyProps: {
        color: "primary",
      },
    },
  };

  const Icon = defaultRenderConfiguration[variant].component;
  const defaultProps = defaultRenderConfiguration[variant].props ?? {};
  const typographyDefaultProps = defaultRenderConfiguration[variant].typographyProps ?? {};

  return (
    <Box style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
      {withIcon && (
        <Tooltip title={iconTooltip ? iconTooltip : ""}>
          <Icon fontSize="small" {...defaultProps} {...iconProps} />
        </Tooltip>
      )}

      <Typography
        variant="caption"
        component="span"
        {...typographyDefaultProps}
        {...typographyProps}
        style={{ lineHeight: 1 }}
      >
        {message}
      </Typography>
    </Box>
  );
};
