import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { Button, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { UPSERT_EQUIPMENT } from "../../../../../api";
import { logError } from "../../../../../../../utils/logger";
import { useModal } from "../../../../../../../hooks/useModal";
import { EquipmentActionsMode, FinanceProgramFormValues, UserActionsEvents } from "../../../../../types";
import { ModalsKeys } from "../../../../../../../global";

interface Props extends Partial<UserActionsEvents> {
  isEquipmentExist: boolean;
  isEditEquipmentFormMode: boolean;
  onToggleEquipmentFormMode: (status: boolean) => void;
}

export const EquipmentActions = ({
  isEquipmentExist,
  onEquipmentCreated,
  onEquipmentUpdated,
  isEditEquipmentFormMode,
  onToggleEquipmentFormMode,
}: Props) => {
  const vo = useSelector((state: any) => state.vo);

  const {
    getValues,
    formState: { isDirty },
  } = useFormContext<FinanceProgramFormValues>();

  const [upsertEquipmentRequest, { loading }] = useMutation(UPSERT_EQUIPMENT, {
    context: { authRequired: true },
    onCompleted(data) {
      if (onEquipmentUpdated) onEquipmentUpdated(data?.upsertEquipment?.id);
    },
  });

  const { handleOpen } = useModal(ModalsKeys.CreateEquipment);

  const equipmentActionsMode = !isEquipmentExist ? EquipmentActionsMode.Create : EquipmentActionsMode.Update;

  const handleCreateEquipment = () => {
    handleOpen({ onEquipmentCreated });
  };

  const handleUpdateEquipment = async () => {
    if (!isDirty) {
      return onToggleEquipmentFormMode(false);
    }

    try {
      const {
        make,
        model,
        selectedEquipment,
        newUsed,
        year,
        quantity,
        mileage,
        totalWholesaleCost,
        stockId,
        retailPrice,
        serialNumberVin,
        description,
      } = getValues("equipment");

      const input: any = {
        equipmentId: selectedEquipment,
        dynamicsVendorOpportunityId: vo.dynamicsVendorOpportunityId,
        vendorOpportunityId: vo.vendorOpportunityId,
        make: make,
        model: model,
        newUsed: newUsed,
        year: year.toString(),
        quantity: quantity ? Number(quantity) : 1,
      };

      if (serialNumberVin) {
        input.serialNumberVin = serialNumberVin;
      }

      if (mileage) {
        input.mileage = Number(mileage.replace(/,/g, ""));
      }

      if (stockId) {
        input.stockId = stockId;
      }

      if (description) {
        input.additionalDescription = description;
      }

      if (retailPrice) {
        input.retailPrice = +retailPrice;
      }

      if (totalWholesaleCost) {
        input.totalWholesaleCost = +totalWholesaleCost;
      }

      await upsertEquipmentRequest({ variables: { input } });
      onToggleEquipmentFormMode(false);
    } catch (error) {
      logError(error);
    }
  };

  const equipmentActionsByMode: Record<EquipmentActionsMode, ReactNode> = {
    [EquipmentActionsMode.Create]: (
      <Button size="small" variant="contained" onClick={handleCreateEquipment}>
        Add Equipment
      </Button>
    ),
    [EquipmentActionsMode.Update]: (
      <>
        {isEditEquipmentFormMode ? (
          <IconButton size="small" color="primary" disabled={loading} onClick={handleUpdateEquipment}>
            <Tooltip title="Save Equipment changes">
              <SaveIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <Tooltip title="Update Equipment">
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                onToggleEquipmentFormMode(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    ),
  };

  return <>{equipmentActionsByMode[equipmentActionsMode]}</>;
};
