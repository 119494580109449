import React, { ReactNode } from "react";
import { Nullable } from "global";
import { formatCurrency } from "utils";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CashOptionSummary } from "./CashOptionSummary";
import { NotificationAlert } from "../NotificationAlert";
import { OptionSummaryDetails } from "./OptionSummaryDetails";
import { ProfitDetails } from "../paymentOptions/ProfitDetails";
import { MenuOptionProductsDescriptions } from "./MenuOptionProductsDescriptions";
import { AftermarketMenuOption, PaymentInfoRenderConfig, Pricing, SaleType } from "../../../types";

interface Props {
  saleType?: SaleType;
  isSectionOpen?: boolean;
  payment: Nullable<Pricing>;
  isSelectedMenuOption: boolean;
  priceOfProductsInMenu?: number;
  financeProgramDescription: string;
  isMAALessThenFinanceAmount?: boolean;
  menuOption: Nullable<AftermarketMenuOption>;
}

export const MenuOptionDetail = ({
  payment,
  menuOption,
  isSectionOpen,
  isSelectedMenuOption,
  financeProgramDescription,
  priceOfProductsInMenu = 0,
  saleType = SaleType.Financing,
  isMAALessThenFinanceAmount = false,
}: Props) => {
  const classes = useStyles({ isSectionOpen: isSectionOpen ?? true });

  const renderData: Record<SaleType, PaymentInfoRenderConfig[]> = {
    [SaleType.Financing]: [
      {
        label: "Finance Profit",
        value: payment?.financeProfit ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Dealer Markup (%)",
        value: payment?.dealerMarkup ?? null,
        formatter: value => (value ? `${value}%` : "0.00%"),
      },
      {
        label: "Aftermarket Profit",
        value: payment?.aftermarketProfit ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Customer Rate",
        value: payment?.customerRate ?? null,
        formatter: value => (value ? `${value}%` : "0.00%"),
      },
      {
        label: "Product Cost",
        value: payment?.productCost ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Total profit",
        value: payment?.totalProfit ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Profit %",
        value: payment?.profitPercentage ?? null,
        formatter: value => (value ? `${value}%` : "0%"),
      },
      {
        label: "Due in Advance",
        value: payment?.amountDueToday ?? null,
        hideInfoMessage: isMAALessThenFinanceAmount,
        formatter: value => formatCurrency(value ?? "0", true),
        infoMessage: "Invoice Total plus all backend products not financed by lender. See Max Approval Amount.",
      },
      {
        label: "Cost of Deferred Payments",
        value: payment?.costOfDeferredPayments ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
    ],
    [SaleType.Cash]: [
      {
        label: "Aftermarket Profit",
        value: payment?.aftermarketProfit ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Product Cost",
        value: payment?.productCost ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Total profit",
        value: payment?.totalProfit ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Due in Advance",
        value: payment?.amountDueToday ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
    ],
  };

  const descriptionSection: Record<SaleType, ReactNode> = {
    [SaleType.Cash]: <CashOptionSummary priceOfProductsInMenu={priceOfProductsInMenu} />,
    [SaleType.Financing]: (
      <OptionSummaryDetails financeProgramDescription={financeProgramDescription} menuOption={menuOption} />
    ),
  };

  return (
    <Box className={classes.container}>
      {!isSelectedMenuOption ? (
        <NotificationAlert message="Select Option Above to Calculate" />
      ) : (
        <ProfitDetails renderConfig={renderData[saleType]} />
      )}

      {!!menuOption?.products?.length && <MenuOptionProductsDescriptions menuOption={menuOption} />}

      {descriptionSection[saleType]}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isSectionOpen: boolean }>({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
});
