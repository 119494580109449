import { gql } from "apollo-boost";

export const GET_CREDIT_SUBMISSIONS_OPTIONS = gql`
  query CreditSubmissionOptions($id: ID!) {
    creditSubmissionsOptions: creditSubmissionsByDynamicsVOId(id: $id) {
      id: creditSubmissionId
      lenderName
      approvedAmount
      decision
    }
  }
`;
