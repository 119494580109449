import React, { useState } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export const DateFieldComp = props => {
  const classes = useStyles();

  const { field, index, handleBlur, inputProps, setReference, setReferenceToRequiredFunction } = props;

  const [required, setRequired] = useState(field.details.required === "REQUIRED");
  const [value, setValue] = useState(field.mappedValue ? moment(field.mappedValue) : null);

  const formatValue = value => (value ? moment(value).format("MM-DD-YYYY") : null);

  setReference(field.lenderProperty, v => {
    setValue(v ? moment(v) : null);
    handleBlur(v, index);
  });

  setReferenceToRequiredFunction(field.lenderProperty, setRequired);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} key={index}>
      <DatePicker
        disableToolbar
        InputLabelProps={{
          disableAnimation: true,
          shrink: true,
        }}
        inputVariant="outlined"
        format="MM/DD/yyyy"
        margin="normal"
        label={(required ? "* " : "") + field.details.displayName}
        error={!field.mappedValue && required}
        value={value}
        onChange={val => setValue(formatValue(val))}
        onBlur={() => handleBlur(formatValue(value), index)}
        views={["year", "month", "date"]}
        openTo="year"
        readOnly={field.details.readOnly}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        className={classes.textField}
        inputProps={inputProps}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
});
