import React, { useEffect } from "react";
import { AftermarketProductQualifiedEventOptions } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/product-qualified/aftermarket-product-qualified-event-options";
import { DynamicFields } from "./DynamicFields";
import { PricingResults } from "./PricingResults";
import { allFacts } from "../../../../../constants";
import { useRulesEngine } from "../../../../../context";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import { MarkupType, ProposalProductCardModes, ProposalProductCardProps } from "../../../../../types";

export const Card = ({
  mode,
  product,
  type = "simple",
  factsToCheck = [],
  factsSkipped = [],
  isNeedRunJsonEngine = 0,
  updateProductConfiguration,
  proposalProductConfiguration,
}: ProposalProductCardProps) => {
  const { engine, handleAddProductsRules } = useRulesEngine();

  Object.values(allFacts).forEach(factKey => {
    const factFromConfig = proposalProductConfiguration[factKey];

    if (!factFromConfig) return;
    engine.addFact(factKey, factFromConfig);
  }, {});

  useEffect(() => {
    handleAddProductsRules(product, updateProductConfiguration);
  }, [product]);

  const handelRunEngine = () => {
    Object.entries(proposalProductConfiguration).forEach(([field, value]) => {
      engine.addFact(field, value);
    });

    engine
      .run()
      .then(engineResults => {
        if (!engineResults.events.length) {
          // When events.length is 0, it means that the rules engine did not find any rules that matched the facts
          updateProductConfiguration({ isPricingValid: false });
          return;
        }

        engineResults.events.map(event => {
          if (event.type === "AFTERMARKET_PRODUCT_GET_DYNAMIC_PRODUCTS") {
            engine.removeRule("NTP Product Dynamic Coverage");
          } else if (event.type === "OVERRIDE_STRING_FACT_OPTIONS") {
            try {
              const overrides = event.params?.overrides;
              if (overrides) {
                overrides.forEach((override: any) => {
                  const factName = override.fact;
                  const factToCheckIndex = factsToCheck.findIndex(fact => fact.factKey === factName);
                  const factToCheck = factsToCheck[factToCheckIndex];
                  if (factToCheck?.type === "string") {
                    factsToCheck[factToCheckIndex].options = override.values;
                    // setFactsToCheck([...factsToCheck]);
                  }
                });
              }
            } catch (e) {
              console.log(`Error during OVERRIDE_STRING_FACT_OPTIONS: ${(e as any).message}`);
            }
          } else if (event.type === "AFTERMARKET_PRODUCT_QUALIFIED") {
            const typedEvent: AftermarketProductQualifiedEventOptions = event as AftermarketProductQualifiedEventOptions;
            const dealerCost = typedEvent?.params?.DEALER_COST ?? 0;
            const retailCost = typedEvent?.params?.SUGGESTED_RETAIL_PRICE ?? 0;
            let markupFlatAmount = 0;

            if (typedEvent?.params?.MARKUP?.AMOUNT && Number(typedEvent.params.MARKUP.AMOUNT)) {
              markupFlatAmount = typedEvent.params.MARKUP.AMOUNT;
            } else {
              markupFlatAmount = retailCost - dealerCost;
            }

            updateProductConfiguration({
              retailCost,
              cost: dealerCost,
              customPrice: false,
              isPricingValid: !!typedEvent,
              markup: { markup: markupFlatAmount, type: MarkupType.Flat },
            });
          }
        });
      })
      .catch(console.log);
  };

  // TODO bad approach: should encapsulate this handler to context
  useEffect(() => {
    const isNecessaryRunEngine = !proposalProductConfiguration?.customPrice || type !== "forEdit";

    if (isNecessaryRunEngine) handelRunEngine();
  }, [isNeedRunJsonEngine]);

  return (
    <ProposalCardContainer
      type={type}
      mode={mode}
      factsSkipped={factsSkipped}
      proposalProduct={product}
      logo={product.aftermarketProduct?.productLogoUrl}
      productConfiguration={proposalProductConfiguration}
      updateProductConfiguration={updateProductConfiguration}
    >
      {mode === ProposalProductCardModes.Desking && (
        <PricingResults isMatched={!!proposalProductConfiguration.isPricingValid} factsToCheck={factsToCheck} />
      )}

      <DynamicFields
        mode={mode}
        factsToCheck={factsToCheck}
        productConfiguration={proposalProductConfiguration}
        updateProductConfiguration={updateProductConfiguration}
      />
    </ProposalCardContainer>
  );
};
