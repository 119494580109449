import React from "react";
import { Info } from "@material-ui/icons";
import { Tooltip, Box, Typography } from "@material-ui/core";
import { PaymentInfoRenderConfig } from "../../types";

export const PaymentOptionsInfoItem = ({
  value,
  label,
  formatter,
  infoMessage,
  hideInfoMessage,
}: PaymentInfoRenderConfig) => (
  <Box className="infoItem">
    <Box display="flex" style={{ alignItems: "center", gap: "0.25rem" }}>
      <Typography component="span" variant="body1" color="textSecondary">
        {label}
      </Typography>

      {!hideInfoMessage && infoMessage && (
        <Tooltip title={infoMessage}>
          <Info fontSize="small" color="primary" />
        </Tooltip>
      )}
    </Box>

    <Typography component="span" variant="body2">
      {formatter ? formatter(value) : value}
    </Typography>
  </Box>
);
