import React from "react";
import { FinanceProgramForm } from "../../../../../schema";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ReactQuill from "react-quill";
import { insuranceLabelsByKey } from "modules/financeProgram/constants";
import { InsuranceLabelsByKey } from "modules/financeProgram/types";
import { checkIfQuillValueIsEmpty } from "utils/quillUtils";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const InsuranceCoveragePart = ({ financeProgramFormValues: program }: Props) => {
  const classes = useStyles();

  const insuranceCoverageFormData = program.fourth.insuranceCoverage;

  return Object.keys(insuranceLabelsByKey).map(key =>
    !checkIfQuillValueIsEmpty(insuranceCoverageFormData[key as keyof InsuranceLabelsByKey]) ? (
      <Grid item xs={12} className={classes.detailsContainer} key={key}>
        <Typography className={classes.label}>{insuranceLabelsByKey[key as keyof InsuranceLabelsByKey]}:</Typography>
        <ReactQuill theme="bubble" readOnly value={insuranceCoverageFormData[key as keyof InsuranceLabelsByKey]} />
      </Grid>
    ) : null
  );
};

const useStyles = makeStyles({
  detailsContainer: {
    "&:not(:last-child)": {
      marginBottom: 15,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .ql-editor": {
      padding: "5px 0",
    },
  },
  label: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 500,
  },
});
