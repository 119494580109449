import { useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { useState } from "react";
import {
  CREATE_PROPOSAL_MENU,
  CREATE_PROPOSAL_MENU_OPTION,
  UPDATE_PROPOSAL_MENU,
  UPDATE_PROPOSAL_MENU_OPTION,
} from "../api";
import { asyncForEach } from "utils";

import { menuConstructorSelectors } from "../model";
import { MenuOptionToAddEdit } from "../types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

export function useSavingMenu() {
  const menuOptionsToArchive = useSelector(menuConstructorSelectors.archivedMenuOptions) as MenuOptionToAddEdit[];
  const proposalProductConfigurationsInMenu = useSelector(menuConstructorSelectors.proposalProductConfigurationsInMenu);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createProposalMenu] = useMutation(CREATE_PROPOSAL_MENU);
  const [updateProposalMenu] = useMutation(UPDATE_PROPOSAL_MENU);
  const [createProposalMenuOption] = useMutation(CREATE_PROPOSAL_MENU_OPTION);
  const [updateProposalMenuOption] = useMutation(UPDATE_PROPOSAL_MENU_OPTION);

  const getProductMenuOptionLinkingCriteria = (product: ProposalProduct, menuName: string) => {
    const { cost, retailCost, markup, ...otherConfigValues } = proposalProductConfigurationsInMenu?.[menuName]?.[
      product.proposalProductId
    ];

    return {
      productId: product.proposalProductId,
      cost: Number(cost) ?? product.cost,
      retailCost: Number(retailCost) ?? product.retailCost,
      markup: {
        type: markup?.type ?? product.markup.type,
        markup: Number(markup?.markup) ?? product.markup.markup,
      },
      config: {
        criteriaValues: otherConfigValues ?? [],
      },
    };
  };

  const updateMenu = async (proposalMenu: ProposalMenu): Promise<{ success: boolean }> => {
    setIsLoading(true);

    const getBaseMenuOptionToCreateOrUpdate = (menuOption: MenuOptionToAddEdit) => ({
      name: menuOption.name,
      description: menuOption.description,
      titleColor: menuOption.titleColor,
      titleBgColor: menuOption.titleBgColor,
      type: menuOption.type,
      proposalProductMenuOptionLinkingCriteria: (menuOption.products as ProposalProduct[])?.map(product =>
        getProductMenuOptionLinkingCriteria(product, menuOption.name!)
      ),
    });

    const createMenuOption = async (menuOption: MenuOptionToAddEdit) => {
      try {
        const result = await createProposalMenuOption({
          variables: {
            input: getBaseMenuOptionToCreateOrUpdate(menuOption),
          },
        });
        return result?.data?.createProposalMenuOption?.proposalMenuOptionId;
      } catch (error) {
        console.error(error);
      }
    };

    const updateMenuOption = async (menuOption: MenuOptionToAddEdit) => {
      try {
        const result = await updateProposalMenuOption({
          variables: {
            input: {
              proposalMenuOptionId: menuOption.proposalMenuOptionId,
              ...getBaseMenuOptionToCreateOrUpdate(menuOption),
            },
          },
        });

        return result?.data?.updateProposalMenuOption?.proposalMenuOptionId;
      } catch (error) {
        console.error(error);
      }
    };

    try {
      const menuOptionIds: string[] = [];

      await asyncForEach(proposalMenu.menuOptions, async (menuOption: any) => {
        const proposalMenuOptionId = menuOption.proposalMenuOptionId
          ? await updateMenuOption(menuOption)
          : await createMenuOption(menuOption);
        menuOptionIds.push(proposalMenuOptionId);
      });

      const menuPayload = {
        proposalMenuId: proposalMenu.proposalMenuId,
        name: proposalMenu.name,
        description: proposalMenu.description,
        menuOptionIdsToLink: menuOptionIds,
        linkedToCriterias: proposalMenu.linkedToCriterias?.map(linkedToCriteria => ({
          partnerLinkId: linkedToCriteria.partnerLinkId,
          isReadOnly: linkedToCriteria.isReadOnly,
        })),
      };

      await updateProposalMenu({
        variables: {
          input: menuPayload,
        },
      });

      return { success: true };
    } catch (error) {
      console.error(error);
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const createMenu = async (proposalMenu: ProposalMenu): Promise<{ success: boolean }> => {
    setIsLoading(true);

    const formatMenuOptionForPayload = (menuOption: any /* MenuOptionToAddEdit */) => {
      return {
        name: menuOption.name,
        description: menuOption.description,
        titleColor: menuOption.titleColor,
        titleBgColor: menuOption.titleBgColor,
        type: menuOption.type,
        proposalProductMenuOptionLinkingCriteria: menuOption.products?.map((product: any) =>
          getProductMenuOptionLinkingCriteria(product, menuOption.name!)
        ),
      };
    };

    try {
      const menuPayload = {
        name: proposalMenu.name,
        description: proposalMenu.description,
        newMenuOptions: proposalMenu.menuOptions?.map(formatMenuOptionForPayload),
        linkedToCriterias: proposalMenu.linkedToCriterias?.map(linkedToCriteria => ({
          partnerLinkId: linkedToCriteria.partnerLinkId,
          isReadOnly: linkedToCriteria.isReadOnly,
        })),
      };

      await createProposalMenu({
        variables: {
          input: menuPayload,
        },
      });

      return { success: true };
    } catch (error) {
      console.error(error);
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateMenu,
    createMenu,
    isLoading,
  };
}
