import { FC, ReactNode } from "react";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";
import { SelectOption } from "../../global";
import { FinanceProgramForm } from "./schema";
import { FinanceProgramInsuranceCoverage } from "@trnsact/trnsact-shared-types";

export enum FinanceProgramFormSteps {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
}

export enum FinanceProgramFormMode {
  Create = "create",
  Update = "update",
}

export enum Structures {
  None = "none",
  Markup = "financeProfit",
  Subsidy = "subsidy",
}

export enum MarkupMethods {
  Markup = "markup",
  CustomerRate = "customerRate",
}

export interface StepsActionsConfig {
  step: FinanceProgramFormSteps;
  disabled?: boolean;
}

export interface StepsConfig {
  label: string;
  isValid: boolean;
  step: FinanceProgramFormSteps;
}

export type LenderOptions = LenderProfiles & SelectOption;
export type FinanceCriteriaOptions = SelectOption & {
  formRules: any[];
  createdDateTime: any;
};

export enum ConditionsFieldsTypes {
  Boolean = "boolean",
  Number = "number",
  String = "string",
}

export type AllRules =
  | LENDER_WATERFALL_FACT_NAMES_BOOLEAN
  | LENDER_WATERFALL_FACT_NAMES_NUMERIC
  | LENDER_WATERFALL_FACT_NAMES_STRING;

export type SectionStatus = Record<"details" | "criteria" | "insuranceCoverage" | "structure" | "docs", boolean>;

export interface ConfirmSummaryRenderConfig {
  label: string;
  sectionKey: keyof SectionStatus;
  component: FC<{
    financeProgramFormValues: FinanceProgramForm["program"];
  }>;
}

export interface FinanceFormModalData {
  isReadOnly?: boolean;
  targetLenders?: string[];
  isMultipleLenders?: boolean;
  userType?: "lender" | "dealer";
  lendersOptions: LenderProfiles[];
  dealerVendorProfileOptions?: any[];
  isLendersSelectDisabled?: boolean;
  program?: FinanceProgramForm["program"];
  criteriaOptions: FinanceCriteriaOptions[];
  onSubmit: (formValue: FinanceProgramForm) => Promise<void>;
}

export interface PreviewFinanceFormModalData {
  program: FinanceProgramForm["program"];
  onEdit: VoidFunction;
}

export type InsuranceLabelsByKey = Omit<Record<keyof FinanceProgramInsuranceCoverage, string>, "__typename">;
