import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  FormInputSelect,
  FormRadioGroup,
  FormSwitch,
  InputField,
  InterestInputField,
  MultiSelectWithCustomOptions,
} from "components/form";
import { FeeArrayFields } from "./FeeArrayFields";
import { calcTermsSelectOptions } from "../../../../../../lib";
import { StructureConfiguration } from "./StructureConfiguration";
import { deskingActions, deskingSelectors } from "../../../../../../model";
import { FinanceProgramFormValues, ProgramTypes, RateTypes } from "../../../../../../types";
import {
  paymentCalculationMethodOptions,
  programTypeOptions,
  rateTypeOptions,
  styleFormRadioGroupProps,
} from "../../../../../../constants";

export const FinanceCommonFields = () => {
  const dispatch = useDispatch();

  const menuTerms = useSelector(deskingSelectors.menuTerms);

  const { control, setValue, getValues } = useFormContext<FinanceProgramFormValues>();
  const { fields: specifyRatesFields } = useFieldArray({ control, name: "financeQuote.specifyRates" });
  const financeQuote = useWatch({ control, name: "financeQuote" });

  const { terms, specifyRate, specifyRates, commonRate, programType, rateType } = financeQuote;

  const handleChangeTerms = (selected: (string | number)[]) => {
    if (selected.length === terms.length) return;

    const termsToString = selected.toSorted((a, b) => Number(b) - Number(a)).map(String);

    setValue("financeQuote.terms", termsToString);

    const termsToAdd = termsToString.find(term => !menuTerms.includes(term));

    if (termsToAdd && getValues("financeQuote.menu")) {
      dispatch(deskingActions.addMenuTerm({ term: termsToAdd, menuId: getValues("financeQuote.menu") }));
    }

    if (specifyRate) {
      const nextSpecifyRates = termsToString.map((term, index) => ({
        term,
        rate: specifyRates?.[index]?.rate ?? "",
      }));

      setValue("financeQuote.specifyRates", nextSpecifyRates);
    } else {
      setValue(
        "financeQuote.specifyRates",
        termsToString.map(term => ({ term, rate: commonRate }))
      );
    }

    const termForRemove = menuTerms.find(term => !termsToString.includes(term));

    if (menuTerms.length && termForRemove) {
      dispatch(deskingActions.removeMenuTerm({ term: termForRemove }));
    }
  };

  return (
    <>
      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.rateType"
          render={({ field }) => (
            <FormRadioGroup
              row
              label="Rate type"
              options={rateTypeOptions}
              {...styleFormRadioGroupProps}
              {...field}
              onChange={(event, value) => {
                if (value === RateTypes.RateFactor) setValue("financeQuote.specifyRate", true);
                field.onChange(event, value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="financeQuote.specifyRate"
          render={({ field }) => (
            <FormSwitch
              label="Specify rate per term"
              disabled={rateType === RateTypes.RateFactor}
              {...field}
              checked={field.value}
            />
          )}
        />
      </Box>

      <Box className="row">
        <MultiSelectWithCustomOptions
          isOptionsSorted
          control={control}
          name="financeQuote.terms"
          onChange={handleChangeTerms}
          options={calcTermsSelectOptions(terms)}
          selectProps={{ label: "Terms in Months" }}
          onCustomOptionAdded={() => {
            setValue("financeQuote.customTermTemporaryValue", null);
          }}
          selectFormControlProps={{ required: true, variant: "standard" }}
          inputProps={{
            type: "number",
            label: "Add custom term",
            placeholder: "Enter your own term",
            onInputChanged: value => {
              setValue("financeQuote.customTermTemporaryValue", value);
            },
          }}
        />

        {!specifyRate && (
          <InterestInputField
            control={control}
            label="Buy Rate (%)"
            name="financeQuote.commonRate"
            textFieldProps={{ required: true, fullWidth: true, size: "small" }}
          />
        )}
      </Box>

      {!!specifyRate && !!specifyRatesFields?.length && (
        <>
          {specifyRatesFields.map((field, index) => (
            <InterestInputField
              key={field.id}
              control={control}
              name={`financeQuote.specifyRates.${index}.rate`}
              textFieldProps={{ required: true, fullWidth: true }}
              label={`Buy Rate ${field?.term ?? ""} months (%)`}
            />
          ))}
        </>
      )}

      <StructureConfiguration />

      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.programType"
          render={({ field }) => (
            <FormInputSelect variant="standard" label="Program Type" options={programTypeOptions} {...field} />
          )}
        />

        {programType === ProgramTypes.Lease && (
          <InterestInputField
            control={control}
            label="Residual (%)"
            name="financeQuote.residual"
            textFieldProps={{ fullWidth: true }}
          />
        )}
      </Box>

      <Box className="row">
        <InputField
          control={control}
          label="Advance Payment (# of)"
          name="financeQuote.advancePayment"
          inputProps={{ type: "number" }}
        />

        <InputField
          control={control}
          label="Days to First Payment"
          name="financeQuote.daysToFirstPayment"
          inputProps={{ type: "number" }}
        />

        <InputField control={control} label="Start Date" name="financeQuote.startDate" inputProps={{ type: "date" }} />
      </Box>

      <Controller
        control={control}
        name="financeQuote.paymentCalculationMethod"
        render={({ field }) => (
          <FormInputSelect
            variant="standard"
            label="Payment Calculation Method"
            options={paymentCalculationMethodOptions}
            {...field}
          />
        )}
      />

      <FeeArrayFields />
    </>
  );
};
