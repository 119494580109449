import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChromePicker, Color, ColorResult } from "react-color";
import { useModal } from "../../../../hooks/useModal";
import { InputField, SelectField } from "../../../../components/form";
import { useForm } from "react-hook-form";
import {
  MenuOptionColorPickerKeys,
  MenuOptionColorPickerKeysEnum,
  AddEditMenuOptionFormInputs,
  MenuOptionToAddEdit,
} from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { ProposalMenu, ProposalMenuOptionType } from "@trnsact/trnsact-shared-types";
import _ from "lodash";
import { ModalsKeys } from "../../../../global";
import { menuOptionTypeOptions } from "modules/aftermarketMenuConstructor/constants";

export const AddEditMenuOptionDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu) as ProposalMenu;
  const menuOptionToAddEdit = useSelector(menuConstructorSelectors.menuOptionToAddEdit) as MenuOptionToAddEdit;

  const { isOpen, data, handleClose } = useModal(ModalsKeys.AftermarketAddEditMenuOptionDialog);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddEditMenuOptionFormInputs>({
    defaultValues: {
      name: menuOptionToAddEdit.name,
      description: menuOptionToAddEdit.description,
      type: menuOptionToAddEdit.type || ProposalMenuOptionType.Common,
    },
  });

  const isEditing: boolean = !!menuOptionToAddEdit?.name; /* !!menuOptionToAddEdit?.proposalMenuOptionId; */
  const [isColorPickerVisible, setIsColorPickerVisible] = useState<boolean>(false);
  const [currentColorKey, setCurrentColorKey] = useState<MenuOptionColorPickerKeys>(
    MenuOptionColorPickerKeysEnum.TitleColor
  );

  const handleColorClick = (key: MenuOptionColorPickerKeys) => {
    setIsColorPickerVisible(prevState => !prevState);
    setCurrentColorKey(key);
  };

  const handleColorPickerChange = (selectedColor: ColorResult) => {
    dispatch(
      menuConstructorActions.setMenuOptionToAddEdit({
        currentMenuOption: { ...menuOptionToAddEdit, [currentColorKey]: selectedColor.hex },
      })
    );
  };

  const handleColorPickerClose = () => {
    setIsColorPickerVisible(false);
  };

  const handleSave = (formData: AddEditMenuOptionFormInputs) => {
    if (isEditing) {
      dispatch(menuConstructorActions.editMenuOption({ menuOption: { ...menuOptionToAddEdit, ...formData } }));
    } else {
      const maxOrdinal = _.get(_.maxBy(proposalMenu.menuOptions, "ordinal"), "ordinal");
      dispatch(
        menuConstructorActions.addMenuOption({
          menuOption: {
            ...menuOptionToAddEdit,
            ...formData,
            ordinal: maxOrdinal || maxOrdinal === 0 ? maxOrdinal + 1 : 0,
          },
        })
      );
    }
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{isEditing ? "Edit Menu Option" : "Create Menu Option"}</DialogTitle>

      <DialogContent>
        <DialogContentText></DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              label="Name"
              control={control}
              name="name"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.name,
                helperText: errors.name && "Required",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField label="Description" control={control} name="description" />
          </Grid>
          {!isEditing && (
            <Grid item xs={12}>
              <SelectField label="Type" control={control} name="type" options={menuOptionTypeOptions} />
            </Grid>
          )}
          <Grid item container xs={12}>
            <Button
              className={classes.colorSchemaButton}
              onClick={() => handleColorClick(MenuOptionColorPickerKeysEnum.TitleColor)}
              variant="outlined"
            >
              <Typography variant="subtitle2" className={classes.colorSchemaButtonTitle}>
                Title Color
              </Typography>
              <div
                className={classes.colorSchemaCurrentColorContainer}
                style={{ backgroundColor: `${menuOptionToAddEdit.titleColor}` }}
              ></div>
            </Button>
            <Button
              className={classes.colorSchemaButton}
              onClick={() => handleColorClick(MenuOptionColorPickerKeysEnum.TitleBgColor)}
              variant="outlined"
            >
              <Typography variant="subtitle2" className={classes.colorSchemaButtonTitle}>
                Title Background Color
              </Typography>
              <div
                className={classes.colorSchemaCurrentColorContainer}
                style={{ backgroundColor: `${menuOptionToAddEdit.titleBgColor}` }}
              ></div>
            </Button>
          </Grid>
          {isColorPickerVisible && (
            <div className={classes.colorSchemaPopover}>
              <div className={classes.colorSchemaCover} onClick={handleColorPickerClose} />
              <ChromePicker color={menuOptionToAddEdit[currentColorKey] as Color} onChange={handleColorPickerChange} />
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  colorSchemaPopover: {
    position: "absolute",
    zIndex: 2,
  },
  colorSchemaCover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  colorSchemaCurrentColorContainer: {
    width: "25px",
    height: "20px",
    marginLeft: "10px",
    //border: "0.5px solid gray"
  },
  colorSchemaButton: {
    padding: "6px 8px",
    marginRight: "10px",
    background: "#e0e0e0",
  },
  colorSchemaButtonTitle: {
    textTransform: "none",
  },
});
