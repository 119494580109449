import { Fragment, ReactNode } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { DealerManagementTable } from "../../tables/dealerManagementTable/DealerManagementTable";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { AddDealerDialog } from "../../dialogs/AddDealerDialog";
import { modalsSelectors } from "redux/modalsReducer";
import { GET_PARTNER_LINKS, INTEGRATION_SETTINGS } from "modules/aftermarketPartner/api";
import { IntegrationSettingsResponse, PartnerLinksResponse } from "modules/aftermarketPartner/api/types";
import { aftermarketPartnerActions, aftermarketPartnerSelectors } from "modules/aftermarketPartner/model";
import { environmentName } from "../../../../../config";
import { IntegrationSettingsDialog } from "../../dialogs/IntegrationSettingsDialog";

export const AftermarketPartnerDealerManagement = () => {
  const NTP_ACCOUNT_ID =
    environmentName === "dev" ? "aebc4dfe-71db-11ef-a943-db9302946276" : "af389c24-71db-11ef-b67d-438054f5b5e6";

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const partnerLinks = useSelector(aftermarketPartnerSelectors.partnerLinks);
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const isNTPAccount = account.id === NTP_ACCOUNT_ID;

  const { handleOpen: handleAddDealerDialogOpen } = useModal(ModalsKeys.AftermarketPartnerAddDealerDialog);
  const { handleOpen: handleOpenIntegrationSettingsDialog } = useModal(
    ModalsKeys.AftermarketPartnerIntegrationSettingsDialog
  );

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.AftermarketPartnerAddDealerDialog]: <AddDealerDialog />,
    [ModalsKeys.AftermarketPartnerIntegrationSettingsDialog]: <IntegrationSettingsDialog />,
  };

  const { loading: loadingPartnerLinks, refetch: refetchPartnerLinks } = useQuery<PartnerLinksResponse>(
    GET_PARTNER_LINKS,
    {
      variables: {
        dynamicsId: account.dynamicsAccountId,
      },
      onCompleted(data) {
        dispatch(aftermarketPartnerActions.setPartnerLinks(data.getPartnerLinks));
        dispatch(aftermarketPartnerActions.setIsNTPAccount(isNTPAccount));
      },
    }
  );

  useQuery<IntegrationSettingsResponse>(INTEGRATION_SETTINGS, {
    skip: !isNTPAccount,
    variables: { integrationName: "NTP" },
    onCompleted(data) {
      dispatch(aftermarketPartnerActions.setIntegrationSettings(data?.integrationSettings?.integrationSettings));
    },
  });

  return (
    <>
      <CardContainer
        title="Dealer Management"
        actionBtn={
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => handleAddDealerDialogOpen({ refetchPartnerLinks })}
          >
            Add Dealer
          </Button>
        }
      >
        <DealerManagementTable
          loading={loadingPartnerLinks}
          tableData={partnerLinks}
          handleOpenIntegrationSettingsDialog={handleOpenIntegrationSettingsDialog}
        />
      </CardContainer>

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
