import React, { SyntheticEvent } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { AftermarketMenuOption } from "../../../types";

interface Props {
  menuOption: AftermarketMenuOption;
  onEditOption?: (currentMenuOption: AftermarketMenuOption) => void;
  onRemoveOption?: (currentMenuOption: AftermarketMenuOption) => void;
}

export const MenuOptionCardHeader = ({ menuOption, onEditOption, onRemoveOption }: Props) => {
  const classes = useStyles({
    cardPanelTitleColor: menuOption?.titleColor ?? "",
    cardPanelBg: menuOption?.titleBgColor ?? "",
  });

  const handleEditIconClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onEditOption!(menuOption);
  };

  const handleDeleteIconClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onRemoveOption!(menuOption);
  };

  return (
    <Box className={classes.cardPanel}>
      {onEditOption && (
        <Box className={classes.actionButtonContainer}>
          <IconButton size="small" onClick={handleEditIconClick}>
            <SettingsIcon color="primary" />
          </IconButton>
        </Box>
      )}

      <Typography component="span" variant="subtitle2" className={classes.optionTitle}>
        {menuOption.name}
      </Typography>

      {onRemoveOption && (
        <Box className={classes.actionButtonContainer}>
          <IconButton className={classes.actionButton} size="small" onClick={handleDeleteIconClick}>
            <DeleteOutlineIcon color="error" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { cardPanelBg: string; cardPanelTitleColor: string }>({
  cardPanel: {
    padding: "12px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: ({ cardPanelBg }) => cardPanelBg,
    color: ({ cardPanelTitleColor }) => cardPanelTitleColor ?? "black",
  },
  actionButtonContainer: {
    background: "rgba(255, 255, 255, 0.7)",
  },
  optionTitle: {
    flexGrow: 1,
    textAlign: "center",
  },
});
