import _ from "lodash";
import { FACT_NAMES_BOOLEAN, FACT_NAMES_DATE, FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Criteria, FactToCheck, FactToCheckTypes, ProposalProductCardModes } from "../types";
import { factsExcludeForCheck, factsExcludeForMenuConstructorCheck } from "../constants";

export function collectFactsSkippedOnProduct(
  product: ProposalProduct,
  mode: ProposalProductCardModes = ProposalProductCardModes.Desking
): FactToCheck[] {
  const productRules = (product?.aftermarketProduct?.criteria ?? []) as Criteria[];

  if (!productRules.length) return [];

  const factsSkipped: FactToCheck[] = [];

  const factTypeMap: Record<string, FactToCheckTypes> = {
    ...Object.fromEntries(Object.keys(FACT_NAMES_BOOLEAN).map(key => [key, "boolean"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_NUMERIC).map(key => [key, "numeric"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_DATE).map(key => [key, "numeric"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_STRING).map(key => [key, "string"])),
  };

  productRules.forEach(({ conditions }) => {
    conditions?.formRules?.forEach(rule => {
      if (!rule.requiredForQuote) return;

      const factType = factTypeMap[rule.fact] ?? factTypeMap[rule.factName ?? ""];

      if (
        !factType ||
        factsExcludeForCheck.includes(rule.fact) ||
        (mode === ProposalProductCardModes.Constructor && factsExcludeForMenuConstructorCheck.includes(rule.fact))
      ) {
        if (!factsSkipped.find(fact => fact.factKey === rule.fact)) {
          factsSkipped.push({
            factKey: rule.fact,
            type: factType,
            rule,
            options:
              factType === "string" && rule.exactMatch
                ? Array.isArray(rule.exactMatch)
                  ? rule.exactMatch
                  : [rule.exactMatch]
                : null,
          });
        }
      }
    });
  });

  return factsSkipped;
}
