import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";

interface FormInputProps<FormatterValue = string> extends Omit<TextFieldProps, "onChange"> {
  startIcon?: ReactNode;
  onChange?: (value: FormatterValue) => void;
  formatter?: (value: string) => FormatterValue;
}

export const FormInput = <FormatterValue extends string | number = string>({
  error,
  onChange,
  formatter,
  startIcon,
  helperText,
  ...other
}: FormInputProps<FormatterValue>) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    if (onChange) {
      formatter ? onChange(formatter(value)) : onChange(value as FormatterValue);
    }
  };

  const inputProps = startIcon
    ? {
        startAdornment: (
          <InputAdornment position="start" className={classes.startAdornment}>
            {startIcon}
          </InputAdornment>
        ),
      }
    : undefined;

  return (
    <TextField
      fullWidth
      size="small"
      error={error}
      variant="standard"
      onChange={handleChange}
      InputProps={inputProps}
      helperText={error ? helperText : ""}
      {...other}
    />
  );
};

const useStyles = makeStyles({
  startAdornment: {
    margin: "0 0 3px 0",

    "& .MuiSvgIcon-root": {
      fontSize: "1.18rem",
      margin: 0,
    },
  },
});
