import { Payment } from "@trnsact/trnsact-shared-types";

export enum ProposalsViewTypes {
  All = "all",
  My = "my",
}

export interface CellDefaultProps<TableData = any> {
  proposal: TableData;
}

export interface DetailsCellDefaultProps {
  details: any;
}

export interface CellArgs<TableData = any> {
  original: TableData;
}

export interface ColumnFilter {
  value: string[];
  id: string;
}

export enum PaymentOptionsDetailsRenderTypes {
  WithDetermine = "withDetermine",
  WithoutDetermine = "withoutDetermine",
}

export interface PaymentOptionsDetailsRenderOptions {
  variant: PaymentOptionsDetailsRenderTypes;
  acceptedTableData: Payment[];
  declinedTableData: Payment[];
}

export enum AftermarketCatalogDetailsKeys {
  ProductSummary = "productSummary",
  ParagraphDescription = "paragraphDescription",
  CoverageDetails = "coverageDetails",
  EligibilityAndInclusions = "eligibilityAndInclusions",
  LimitationsOfLiability = "limitationsOfLiability",
  LegalDisclaimer = "legalDisclaimer",
}
