import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { useModal } from "../../../../hooks/useModal";
import { FormProvider, useForm } from "react-hook-form";
import { ModalsKeys } from "../../../../global";
import { IntegrationSettingsFormInputs } from "modules/aftermarketPartner/types";
import { IntegrationSettingsTable } from "../tables/integrationSettingsTable/IntegrationSettingsTable";
import {
  formatIntegrationSettingsFormDataToPayload,
  getDealerNumberByLocationFormData,
} from "modules/aftermarketPartner/lib";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  INSERT_INTEGRATION_SETTINGS_VALUES_ARRAY,
  INTEGRATION_SETTINGS_VALUES_LIST,
} from "modules/aftermarketPartner/api";
import { IntegrationSettingsValuesListResponse } from "modules/aftermarketPartner/api/types";

export const IntegrationSettingsDialog = () => {
  const { isOpen, data: modalData, handleClose } = useModal(ModalsKeys.AftermarketPartnerIntegrationSettingsDialog);

  const { loading: isISVLoading } = useQuery<IntegrationSettingsValuesListResponse>(INTEGRATION_SETTINGS_VALUES_LIST, {
    variables: { input: { integrationName: "NTP", accountId: modalData.partnerLink.accountId } },
    onCompleted(data) {
      const dealerNumberByLocationFormData = getDealerNumberByLocationFormData(
        modalData.partnerLink.partnerLocations,
        data.integrationSettingsValuesList
      );
      form.setValue("dealerNumberByLocation", dealerNumberByLocationFormData);
    },
  });

  const [insertIntegrationSettingsValuesArray, { loading: isISVInsertLoading }] = useMutation(
    INSERT_INTEGRATION_SETTINGS_VALUES_ARRAY
  );

  const form = useForm<IntegrationSettingsFormInputs>({
    defaultValues: {
      dealerNumberByLocation: [],
    },
  });

  const handleSave = async (formData: IntegrationSettingsFormInputs) => {
    try {
      const input = formatIntegrationSettingsFormDataToPayload(formData);
      if (input.length) {
        await insertIntegrationSettingsValuesArray({
          variables: { input },
        });
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <Typography component="span" variant="h5" style={{ fontWeight: 500 }}>
          Integration Settings
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isISVLoading && (
              <FormProvider {...form}>
                <IntegrationSettingsTable />
              </FormProvider>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!isISVLoading && !isISVInsertLoading ? (
          <>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={form.handleSubmit(handleSave)} color="primary" variant="contained">
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};
