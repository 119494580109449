import React, { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useQuery } from "react-apollo";
import { batch, useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { CurrencyInputField, FormInputSelect, InputField, SelectField } from "components/form";
import { EquipmentActions } from "./EquipmentActions";
import { GET_PROPOSAL_PRODUCTS } from "../../../../../api";
import { equipmentMileageFormatter } from "../../../../../lib";
import { ProductsDynamicFields } from "./ProductsDynamicFields";
import { ProposalProductsResponse } from "../../../../../api/types";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { FormSectionHeader, NotificationAlert } from "../../../../components";
import { Fact, FinanceProgramFormValues, FormSectionProps, UserActionsEvents } from "../../../../../types";
import {
  dynamicFieldErrorText,
  equipmentConditions,
  equipmentDependentFields,
  equipmentYears,
} from "../../../../../constants";

type Props = FormSectionProps & Partial<UserActionsEvents>;

export const Equipment = ({ handleUpdateOpenStatus, sectionName, ...userActionsEvents }: Props) => {
  const { control, setValue } = useFormContext<FinanceProgramFormValues>();
  const [serialNumberVin, mileage, customerState, financeAmount] = useWatch({
    control,
    name: ["equipment.serialNumberVin", "equipment.mileage", "customer.state", "saleDetails.financeAmount"],
  });

  const dispatch = useDispatch();

  const isEditMode = useSelector(deskingSelectors.isEditEquipmentStatus);
  const isNeedRenderDynamicFields = useSelector(deskingSelectors.isNeedRenderDynamicFields);
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const isSectionCollapsed = useSelector((state: any) => deskingSelectors.isSectionCollapsed(state, sectionName));

  const equipments = useSelector(deskingSelectors.equipment);
  const creditAppLocation = useSelector(deskingSelectors.creditAppLocation);
  const commonProductsConfiguration = useSelector(deskingSelectors.commonProductsConfiguration);

  const makeOptions = useSelector(deskingSelectors.makeEquipmentOptions);
  const classOptions = useSelector(deskingSelectors.classEquipmentOptions);
  const equipmentOptions = useSelector(deskingSelectors.equipmentSelectOptions);

  const proposalProductsVariables = useMemo(
    () => ({
      input: {
        proposalProductCriteria: {
          state: customerState,
          vin: serialNumberVin,
          insuredAmount: financeAmount,
          odometer: Number(equipmentMileageFormatter(mileage, "toSimpleStr")),
          locationId: creditAppLocation?.locationId,
        },
      },
    }),
    [customerState, serialNumberVin, financeAmount, mileage]
  );

  const { refetch: refetchProposalProducts, loading } = useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    variables: proposalProductsVariables,
    onCompleted(response) {
      if (!Array.isArray(response?.proposalProducts)) return;
      dispatch(deskingActions.setProducts({ products: response.proposalProducts }));
    },
  });

  useEffect(() => {
    dispatch(deskingActions.toggleIsProposalProductsLoading({ isLoading: loading }));
  }, [loading]);

  const handleChangeEquipment = async (equipmentId: string) => {
    dispatch(deskingActions.toggleIsEditEquipmentStatus({ status: false }));

    await refetchProposalProducts(proposalProductsVariables);

    const selectedEquipment = equipments?.find(equipment => equipment.equipmentId === equipmentId);

    setValue("equipment.selectedEquipment", equipmentId);

    setValue(`equipment.mileage`, equipmentMileageFormatter(selectedEquipment?.mileage ?? null, "toView"));

    equipmentDependentFields.forEach(field => setValue(`equipment.${field}`, selectedEquipment?.[field] ?? ""));

    batch(() => {
      dispatch(deskingActions.setCurrentEquipmentData({ id: equipmentId }));
      dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
    });
  };

  const handleChangeSelectedProductsConfiguration = (configField: string, nextValue: string) => {
    dispatch(
      deskingActions.updateAllSelectedProductsConfiguration({
        data: { [configField]: nextValue },
      })
    );
  };

  const determineDynamicFieldError = (fact: Fact) => isEditMode && !commonProductsConfiguration[fact];

  return (
    <Box className="section">
      <FormSectionHeader
        name="Equipment"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        isSectionCollapsed={isSectionCollapsed}
        handleUpdateOpenStatus={handleUpdateOpenStatus}
        extraAction={
          <EquipmentActions
            isEquipmentExist={!!equipments?.length}
            isEditEquipmentFormMode={isEditMode}
            onToggleEquipmentFormMode={status => {
              dispatch(deskingActions.toggleIsEditEquipmentStatus({ status }));
            }}
            {...userActionsEvents}
          />
        }
      />

      {isSectionOpen && (
        <>
          {!equipments?.length ? (
            <NotificationAlert message="You don’t have any equipment yet. Please, press ‘Add Equipment’ button to add one." />
          ) : (
            <Box className="sectionFields">
              <Box className="row">
                <Controller
                  control={control}
                  name="equipment.selectedEquipment"
                  render={({ field }) => (
                    <FormInputSelect
                      size="small"
                      variant="standard"
                      label="Select Equipment"
                      options={equipmentOptions}
                      {...field}
                      onChange={handleChangeEquipment}
                    />
                  )}
                />

                <Box />
              </Box>

              <Box className="row">
                <InputField
                  label="Quantity"
                  control={control}
                  name="equipment.quantity"
                  inputProps={{ disabled: !isEditMode }}
                />

                <SelectField
                  label="Year"
                  control={control}
                  name="equipment.year"
                  options={equipmentYears}
                  helperText={dynamicFieldErrorText}
                  inputProps={{ disabled: !isEditMode }}
                  error={determineDynamicFieldError(FACT_NAMES_NUMERIC.EQUIPMENT_YEAR)}
                  extraAction={nextValue => {
                    handleChangeSelectedProductsConfiguration(FACT_NAMES_NUMERIC.EQUIPMENT_YEAR, nextValue);
                  }}
                />

                <SelectField
                  label="Make"
                  control={control}
                  name="equipment.make"
                  options={makeOptions}
                  helperText={dynamicFieldErrorText}
                  inputProps={{ disabled: !isEditMode }}
                  error={makeOptions.length ? determineDynamicFieldError(FACT_NAMES_STRING.EQUIPMENT_MAKE) : false}
                  extraAction={nextValue => {
                    handleChangeSelectedProductsConfiguration(FACT_NAMES_STRING.EQUIPMENT_MAKE, nextValue);
                  }}
                />

                <InputField
                  label="Model"
                  control={control}
                  name="equipment.model"
                  inputProps={{ disabled: !isEditMode }}
                />

                <SelectField
                  label="Class"
                  control={control}
                  name="equipment.class"
                  options={classOptions}
                  helperText={dynamicFieldErrorText}
                  inputProps={{ disabled: !isEditMode }}
                  error={classOptions.length ? determineDynamicFieldError(FACT_NAMES_STRING.EQUIPMENT_CLASS) : false}
                  extraAction={nextValue => {
                    handleChangeSelectedProductsConfiguration(FACT_NAMES_STRING.EQUIPMENT_CLASS, nextValue);
                  }}
                />

                <InputField
                  control={control}
                  label="Serial/VIN#"
                  name="equipment.serialNumberVin"
                  inputProps={{
                    disabled: !isEditMode,
                    helperText: dynamicFieldErrorText,
                    error: determineDynamicFieldError(FACT_NAMES_STRING.VIN),
                  }}
                  extraAction={{
                    debounceTime: 50,
                    cb: nextValue => {
                      handleChangeSelectedProductsConfiguration(FACT_NAMES_STRING.VIN, nextValue);
                    },
                  }}
                />
              </Box>

              <Box className="row">
                <InputField
                  label="Stock Id"
                  control={control}
                  name="equipment.stockId"
                  inputProps={{ disabled: !isEditMode }}
                />

                <SelectField
                  label="Condition"
                  control={control}
                  name="equipment.newUsed"
                  options={equipmentConditions}
                  helperText={dynamicFieldErrorText}
                  inputProps={{ disabled: !isEditMode }}
                  error={determineDynamicFieldError(FACT_NAMES_STRING.EQUIPMENT_CONDITION)}
                  extraAction={nextValue => {
                    handleChangeSelectedProductsConfiguration(
                      FACT_NAMES_STRING.EQUIPMENT_CONDITION,
                      nextValue.toUpperCase()
                    );
                  }}
                />

                <InputField
                  label="Mileage"
                  control={control}
                  name="equipment.mileage"
                  inputProps={{
                    disabled: !isEditMode,
                    helperText: dynamicFieldErrorText,
                    error: determineDynamicFieldError(FACT_NAMES_NUMERIC.ODOMETER_MILEAGE),
                  }}
                  formatter={value => equipmentMileageFormatter(value, "toSimpleStr")}
                  extraAction={{
                    debounceTime: 50,
                    cb: nextValue => {
                      handleChangeSelectedProductsConfiguration(FACT_NAMES_NUMERIC.ODOMETER_MILEAGE, nextValue);
                    },
                  }}
                />

                <InputField
                  control={control}
                  label="Description"
                  name="equipment.description"
                  inputProps={{ disabled: !isEditMode }}
                />

                <CurrencyInputField
                  control={control}
                  label="Wholesale Cost"
                  name="equipment.totalWholesaleCost"
                  textFieldProps={{ disabled: !isEditMode }}
                />

                <CurrencyInputField
                  label="Retail Price"
                  control={control}
                  name="equipment.retailPrice"
                  textFieldProps={{ disabled: !isEditMode }}
                />
              </Box>

              {isNeedRenderDynamicFields && (
                <ProductsDynamicFields onChangeAllProductsConfig={handleChangeSelectedProductsConfiguration} />
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
