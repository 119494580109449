import {
  IntegrationSettingsDealerNumberByLocationForm,
  IntegrationSettingsFormInputs,
} from "modules/aftermarketPartner/types";
import { createTableColumns } from "./tableConfig";
import { useFormContext } from "react-hook-form";
import { Table } from "components/shared/Table";

export const IntegrationSettingsTable = () => {
  const { getValues } = useFormContext<IntegrationSettingsFormInputs>();
  const columns = createTableColumns();

  return (
    <Table<IntegrationSettingsDealerNumberByLocationForm>
      tableData={getValues("dealerNumberByLocation")}
      columns={columns}
    />
  );
};
