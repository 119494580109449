import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PaymentInfoRenderConfig } from "../../../types";

interface Props {
  totalProfit: string;
  renderConfig: PaymentInfoRenderConfig[];
}

export const ProfitSummary = ({ renderConfig, totalProfit }: Props) => {
  return (
    <>
      <Box className="profitSummary">
        <Typography component="span" variant="subtitle2">
          Profit Summary
        </Typography>
      </Box>

      <Box className="profitSummaryContent">
        <Box className="summaryList">
          {renderConfig.map(({ value, label, formatter }) => (
            <Box key={label} className="summaryListItem">
              <Typography component="span" variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
              <Typography component="span" variant="body2" color="textSecondary">
                {formatter ? formatter(value) : value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box className="totalProfit">
          <Typography component="span" variant="subtitle1">
            Total Profit
          </Typography>

          <Typography component="span" variant="subtitle1">
            {totalProfit}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
