import { useEffect } from "react";
import { useQuery } from "react-apollo";
import { useDispatch } from "react-redux";
import { deskingActions } from "../model";
import { GET_CREDIT_SUBMISSIONS_OPTIONS } from "../api";
import { SubmissionsOptionsResponse, SubmissionsOptionsVariables } from "../api/types";

interface Options {
  voDynamicId: string;
}

export function useSubmissions({ voDynamicId }: Options) {
  const dispatch = useDispatch();

  const { loading } = useQuery<SubmissionsOptionsResponse, SubmissionsOptionsVariables>(
    GET_CREDIT_SUBMISSIONS_OPTIONS,
    {
      skip: !voDynamicId,
      variables: { id: voDynamicId },
      onCompleted(response) {
        dispatch(deskingActions.setSubmissions({ submissions: response?.creditSubmissionsOptions ?? [] }));
      },
    }
  );

  useEffect(() => {
    dispatch(deskingActions.toggleSubmissionLoading({ status: loading }));
  }, [loading]);
}
